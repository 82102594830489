import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { navList } from './DrawerStructure.js';
import AuthSrvc from '../../../service/AuthSrvc';
import { withTranslation } from 'react-i18next';

class DrawerCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
        }
    }

    componentDidMount() {
        // initialize state
        navList.forEach((item) => {
            if (item.sub) {
                this.setState[item.text + '_COLLAPSED'] = true;
            }
        }, this);
    }

    componentDidUpdate(prevProps){
        // do auth srvc logic here
    }

    handleClick(key) {
        const prevValue = this.state[key + '_COLLAPSED'];
        this.setState({ [key + '_COLLAPSED']: !prevValue} );
    }

    translate(key) {
        return this.props.t('SIDEBAR.' + key);
    }

    drawerContent() {
        return navList.map((item) => {
            let shouldRender;
            if (item.type === 'AUTHED') {
                shouldRender = AuthSrvc.isLoggedIn ? true : false;
            } else if (item.type === 'NOT_AUTHED') {
                shouldRender = !AuthSrvc.isLoggedIn ? true : false;
            } else if (item.type === 'STATIC') {
                shouldRender = true;
            } else {
                shouldRender = false;
            }
            if (!shouldRender) {
                return null;
            }
            // ?sub => if it has submenu
            if (!item.sub) {
                return (
                    <ListItem button key={item.text} name={item.text} onClick={() => {
                        this.props.history.push(item.url);
                        this.props.onClick();
                    }}>
                        <ListItemText primary={this.translate(item.text)} />
                    </ListItem>
                );
            }
            const closed = this.state[item.text + '_COLLAPSED'];
            return (
                <div key={item.text}>
                    <ListItem button onClick={() => this.handleClick(item.text)}>
                        <ListItemText primary={this.translate(item.text)} />
                        {closed ? <ExpandMore /> : <ExpandLess />}
                    </ListItem>
                    <Collapse in={!closed} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                item.sub.map((sub) => (
                                    <ListItem key={sub.text} button onClick={() => {
                                        this.props.history.push(sub.url);
                                        this.props.onClick();
                                    }}>
                                        <ListItemText primary={this.translate(sub.text)} style={{ paddingLeft: 20 }} />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Collapse>
                </div>
            );
        });
    }

    render() {
        const drawerContent = this.drawerContent();
        return (
            <List style={{ width: 250 }}>
                {drawerContent}
                { AuthSrvc.isLoggedIn &&
                    <ListItem button onClick={this.props.onLogout}>
                        <ListItemText primary='Logout' />
                    </ListItem>
                }
            </List>
        );
    }
};

export default withTranslation()(withRouter(DrawerCmp));
