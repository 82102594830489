import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import { Grid, Button } from '@material-ui/core';

import { getListingTypeStr, getPropertyTypeStr, getListingStatusStr } from '../../util/StringTypeConverter.js';
import { LISTING_STATUS } from '../../util/Enum/EnumConstants.js';
import EnumManagerInstance from '../../util/Enum/EnumManager.js';

import Logo from '../../assets/logo/logo_transparent.png';

class PropertyThumbEditCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let mainImage;
        if (this.props.data && this.props.data.propertypictureSet && this.props.data.propertypictureSet[0] && this.props.data.propertypictureSet[0].thumbnail) {
            mainImage = this.props.data.propertypictureSet[0].thumbnail
        } else {
            mainImage = Logo
        }
        return (
            <Grid item xs={12} md={4}>
                <Grid container alignItems="flex-start" direction="column" style={{ padding: 20 }}>
                    <Grid item xs={12} style={{ width: '100%' }}>
                        <img src={mainImage} alt="main_image" style={{ height: 250, cursor: "pointer" }} onClick={() => this.props.onClickGoToListing(this.props.data.propertyInfoId)}></img>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        {this.props.data.formattedAddress}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        {getListingTypeStr(this.props.data.listingType)}, {getPropertyTypeStr(this.props.data.propertyType)}, {this.props.data.bedroom} Bed(s) {this.props.data.bathroom} Bath(s)
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        Price: Rp. {this.props.data.price}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        Listing status: {getListingStatusStr(this.props.data.listingStatus)}
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }} spacing={1}>
                        <Grid item>
                            <Button variant="contained" style={{ backgroundColor: 'cadetblue'}} size="medium" onClick={() => this.props.onClickEditProp(this.props.data.propertyInfoId)}>Edit</Button>
                        </Grid>
                        {/* show already sold button if listing is ACTIVE */}
                        {
                            this.props.data.listingStatus === EnumManagerInstance.getListingStatusEnumMap()[LISTING_STATUS.ACTIVE] &&
                            <Grid item>
                                <Button variant="contained" size="medium" style={{ backgroundColor: 'yellowgreen' }} onClick={() => this.props.onClickAlreadySoldProp(this.props.data.propertyInfoId)}>Mark As Sold</Button>
                            </Grid>
                        }
                        <Grid item>
                            <Button variant="contained" style={{ backgroundColor: 'lightblue'}} size="medium" onClick={() => this.props.onClickPropertyAnalytics(this.props.data.propertyInfoId)}>See All Activities</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default PropertyThumbEditCmp;
