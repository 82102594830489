export function setAlert(message) {
    return {
        type: 'SET_ALERT',
        payload: message
    };
}

export function resetAlert() {
    return {
        type: 'RESET_ALERT',
    };
}
