var defaultState = {
    formType: 'login', // ['login', 'signup']
    userType: 'normal', // ['normal', 'agent']
    auth: {},
    email: '',
    password: '',
    authInfo: {
        userProfileId: '',
        userEmail: '',
        agentId: null,
        token: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        profilePictureThumbnail: ''
    },
    agentInfo: {
        firstName: '',
        lastName: ''
    },
    hasError: false,
    errorMessage: '',
    isLoggedIn: false
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case 'SWITCH_LOGIN_FORM':
            return {...state, formType: 'login'};
        case 'SWITCH_SIGNUP_FORM':
            return { ...state, formType: 'signup' };
        case 'SWITCH_TO_NORMAL_USER':
            return { ...state, userType: 'normal' };
        case 'SWITCH_TO_AGENT':
            return { ...state, userType: 'agent' };
        case 'UPDATE_LOGIN_DATA':
            return { ...state, email: action.payload.email, password: action.payload.password, agentInfo: action.payload.agentInfo }
        case 'ERROR_LOGIN_DATA':
            return { ...state, email: '', password: '', hasError: true, errorMessage: action.payload }
        case 'RESET_LOGIN_DATA':
            return { ...state, email: '', password: '', hasError: false, errorMessage: '' }
        case 'VERIFIED_LOGIN':
        case 'PROFILE_PICTURE_CHANGED':
        case 'USER_PROFILE_INFO_CHANGED':
            const newAuthInfo = {
                userProfileId: action.payload.userProfileId || state.authInfo.userProfileId || '',
                agentId: action.payload.agentId || state.authInfo.agentId || '',
                userEmail: action.payload.userEmail || state.authInfo.userEmail || '',
                token: action.payload.token || state.authInfo.token || '',
                firstName: action.payload.firstName || state.authInfo.firstName || '',
                lastName: action.payload.lastName || state.authInfo.lastName || '',
                phoneNumber: action.payload.phoneNumber || state.authInfo.phoneNumber || '',
                profilePictureThumbnail: action.payload.profilePictureThumbnail || state.authInfo.profilePictureThumbnail || ''
            }
            return { ...state, isLoggedIn: true, password: '', hasError: false, errorMessage: '', authInfo: newAuthInfo }
        case 'LOGOUT':
            return defaultState;
        default:
            return state;
    }
}
