import React, { Component } from 'react';

import { FormControl, InputLabel, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
class FilterSelectorCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            currentSelection: -1,
        }
        if (this.props.currentVal !== null && this.props.currentVal !== undefined) {
            this.state.currentSelection = this.props.currentVal;
        }
    }

    /* onChange can return 'true' when it's invalid action */
    handleChange = (event) => {
        const newVal = event.target.value;
        if (this.props.onChange(newVal)) {
            return;
        };
        this.setState(() => ({ currentSelection: newVal }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentVal !== this.props.currentVal) {
            this.setState({currentSelection: this.props.currentVal});
        }
    }

    render() {
        function getItems(choices) {
            if (Array.isArray(choices)) {
                return choices.map(key =>
                    <option key={key} value={key}>{key.toString()}</option>
                );
            }
            return Object.keys(choices).map(key =>
                <option key={key} value={key}>{choices[key].toString()}</option>
            );
        }
        return (
            <FormControl margin="normal" fullWidth className={this.state.classes.formControl} style={this.props.extraStyle}>
                <InputLabel htmlFor={this.props.filterName}>{this.props.filterName}</InputLabel>
                <Select
                    native
                    size="medium"
                    value={this.state.currentSelection}
                    onChange={this.handleChange}
                    inputProps={{
                        name: this.props.filterName,
                    }}
                >
                    <option value={-1}>
                        None Applied
                    </option>
                    {getItems(this.props.choices)}
                </Select>
            </FormControl>
        )
    }
}

export default FilterSelectorCmp;
