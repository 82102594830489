import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    changePropertySelectorCmpState,
    resetPropertySelectorCmpState
} from '../../actions/PropertySelectorCmp/index.js';
import { LISTING_TYPE } from '../../util/Enum/EnumConstants.js';
// material-ui
import { Button, Grid } from '@material-ui/core';

class PropertySelectorCmp extends Component {
    state = {
        currentSelection: LISTING_TYPE.RENT,
        type: Object.keys(LISTING_TYPE)
    }
    componentWillMount = () => {
        this.props.resetPropertySelectorCmpState();
    }
    handleButtonClick = (newType) => {
        this.setState(() => ({currentSelection: newType}))
        this.props.changePropertySelectorCmpState({propertyType: newType});
    }

    render() {
        return (
            <Grid container spacing={6} justify="center">
                {
                    this.state.type.map((type) =>(
                        <Grid item key={type}>
                            <Button
                                variant={this.state.currentSelection === type ? 'contained' : 'outlined'}
                                color="primary"
                                size="medium"
                                onClick={() => this.handleButtonClick(type)}>{LISTING_TYPE[type]}</Button>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.listingTypeSelectorStates
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        changePropertySelectorCmpState,
        resetPropertySelectorCmpState
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PropertySelectorCmp);
