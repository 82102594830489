import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';
import PropertyThumbCmp from '../PropertyThumbCmp/PropertyThumbCmp.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class PropertyListCmp extends Component {
    // function passed to child component (`this` refers to child component's context)
    handleToPropertyInfo = (propertyData) => {
        const propertyInfoId = propertyData.propertyInfoId
        this.props.history.push(`/property/${propertyInfoId}`, {propertyData});
    }

    render() {
        return (
            <Grid container item>
                {/* <MapCmp propertyResultData={this.props.resultStates.propertyResultData} /> */}
                    {this.props.data && this.props.data.map((properties) =>
                        <PropertyThumbCmp
                            key={properties.propertyInfoId}
                            data={properties}
                            isLoggedIn={this.props.authStates.isLoggedIn}
                            handleToPropertyInfo={this.handleToPropertyInfo.bind(this)}
                        />
                    )}
            </Grid>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        urlState: ownProps.match,
        authStates: state.authStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(PropertyListCmp));
