import ApiSrvc from '../../service/ApiSrvc';
import EnumManagerInstance from '../../util/Enum/EnumManager.js';

export function selectPriceMin(response) {
    return {
        type: 'SELECT_PRICE_MIN',
        payload: response
    }
}

export function selectPriceMax(response) {
    return {
        type: 'SELECT_PRICE_MAX',
        payload: response
    }
}

export function selectPropertyType(response) {
    return {
        type: 'SELECT_PROPERTY_TYPE',
        payload: response
    }
}

export function selectBedNumber(response) {
    return {
        type: 'SELECT_BED_NUMBER',
        payload: response
    }
}

export function selectBathNumber(response) {
    return {
        type: 'SELECT_BATH_NUMBER',
        payload: response
    }
}

export function selectAmenities(response) {
    return {
        type: 'SELECT_AMENITIES',
        payload: response
    }
}

export function postFetchProperty(response) {
    return {
        type: 'FETCH_PROPERTY_DATA',
        payload: response.map((obj) => obj.node) || []
    }
}

function buildFilterQueryObject(resultStates, searchInputStates, listingTypeSelectorStates) {
    // TODO: handle fallback properly (EnumManagerInstance.getListingTypeEnumMap()[listingTypeSelectorStates.propertyType] || -1)
    //       doesn't work because (0 || -1) is -1, and 0 is valid
    const listingTypeEnum = EnumManagerInstance.getListingTypeEnumMap()[listingTypeSelectorStates.listingType];
    const propertyTypeEnum = resultStates.propertyType;
    const query = `
        query ($unit: DistanceUnitEnum!,
            $value: Float!,
            $geometry: Geometry!) {
            allPropertyInfos(
                    locationDistanceLte: {
                        unit: $unit,
                        value: $value,
                        geometry: $geometry
                    },
                    ${resultStates.priceMax > -1 ? ('priceLte:' + resultStates.priceMax + ',') : ''}
                    ${listingTypeEnum !== undefined ? ('listingType:' + listingTypeEnum + ',') : ''}
                    ${resultStates.priceMin > -1 ? ('priceGte:' + resultStates.priceMin + ',') : ''}
                    ${propertyTypeEnum !== undefined ? ('propertyType: ' + propertyTypeEnum + ',') : ''}
                    ${resultStates.numBed > -1 ? ('bedroomGte:' + resultStates.numBed + ',') : ''}
                    ${resultStates.numBath > -1 ? ('bathroomGte:' + resultStates.numBath + ',') : ''}
                    ${resultStates.amenities ? ('amenities:"' + resultStates.amenities + '",') : ''}
                ) {
                edges{
                    node {
                        properties {
                            propertyInfoId
                            formattedAddress
                            propertyType
                            listingType
                            bathroom
                            bedroom
                            price
                            userProfile {
                                firstName
                                lastName
                                email
                                phoneNumber
                            }
                            propertypictureSet {
                                propertyPictureId
                                thumbnail
                                original
                            }
                        }
                        geometry {
                            coordinates
                        }
                    }
                }
            }
        }`;
    return query;
}

function buildSearchQueryObject(searchInputStates, listingTypeSelectorStates) {
    // TODO: handle fallback properly (EnumManagerInstance.getListingTypeEnumMap()[listingTypeSelectorStates.propertyType] || -1)
    //       doesn't work because (0 || -1) is -1, and 0 is valid
    const listingTypeEnum = EnumManagerInstance.getListingTypeEnumMap()[listingTypeSelectorStates.propertyType];
    const searchQuery = searchInputStates.searchQuery;

    let mainSearchQuery = ''
    if (searchQuery.lat && searchQuery.lng) {
        mainSearchQuery =
        `locationDistanceLte: {
            unit: $unit,
            value: $value,
            geometry: $geometry
        },
        ${listingTypeEnum !== undefined ? ('listingType:' + listingTypeEnum + ',') : ''}
        `;
    } else if (searchQuery.propertyInfoId) {
        mainSearchQuery = `propertyInfoIds: "${searchInputStates.searchQuery.propertyInfoId}"`;
    }

    const query = `
        ${searchQuery.lat && searchQuery.lng ? ('query ($unit: DistanceUnitEnum!, $value: Float!, $geometry: Geometry!)') : ''}
        {
            allPropertyInfos(
                ${mainSearchQuery}
                ) {
                edges{
                    node {
                        properties {
                            administrativeAreaLevel3
                            amenities
                            propertyInfoId
                            formattedAddress
                            propertyType
                            listingType
                            bathroom
                            bedroom
                            price
                            spaceSizeM2
                            userProfile {
                                firstName
                                lastName
                                email
                                phoneNumber
                            }
                            propertypictureSet {
                                propertyPictureId
                                thumbnail
                                original
                            }
                        }
                        geometry {
                            coordinates
                        }
                    }
                }
            }
        }`;
    return query;
}

export function searchProperty() {
    return function (dispatch, getState) {
        const { searchInputStates, listingTypeSelectorStates } = getState();
        if (!searchInputStates.searchQuery) {
            return;
        }
        const query = buildSearchQueryObject(searchInputStates, listingTypeSelectorStates);
        let variables = ''
        // it's ok to be bigger than circle size in the map to present properties in more area.
        const radiusSearchSize = searchInputStates.searchQuery.isAdminLevel4Present ? 7000 : 12000
        if (searchInputStates.searchQuery.lat && searchInputStates.searchQuery.lng) {
            variables = `
                    {
                        "unit": "m",
                        "value": ${radiusSearchSize},
                        "geometry": "{'type': 'Point', 'coordinates':[${searchInputStates.searchQuery.lat}, ${searchInputStates.searchQuery.lng}]}"
                    }
            `
        }
        return ApiSrvc.makeQuery(query, variables)
            .then(function (response) {
                const data = response.data.allPropertyInfos.edges;
                dispatch(postFetchProperty(data));
            })
            .catch((error) => {
                console.error(error, "[searchProperty] error fetching data");
            });
    }
}

export function fetchProperty() {
    return function (dispatch, getState) {
        const { resultStates, searchInputStates, listingTypeSelectorStates} = getState();
        if (!searchInputStates.searchQuery) {
            return;
        }
        const query = buildFilterQueryObject(resultStates, searchInputStates, listingTypeSelectorStates);
        let variables = `
            {
                "unit": "km",
                "value": 10,
                "geometry": "{'type': 'Point', 'coordinates':[${searchInputStates.searchQuery.lat}, ${searchInputStates.searchQuery.lng}]}"
            }
        `
        return ApiSrvc.makeQuery(query, variables)
            .then(function (response) {
                const data = response.data.allPropertyInfos.edges;
                dispatch(postFetchProperty(data))
            })
            .catch((error) => {
                console.error(error, "[fetchProperty] error fetching data");
            });
    }
}
