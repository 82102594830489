import LocalStorageSrvc from './LocalStorageSrvc';
import ApiSrvc from './ApiSrvc';

const verifyTokenQuery = (token = '') => `
    mutation verifyToken {
        verifyToken(token: "${token}") {
            payload
            agent {
                agentId
            }
        }
    }
`;

const loginQuery = (email = '', password = '') => `
            mutation TokenAuth {
                tokenAuth(email: "${email}", password: "${password}") {
                    token
                    userProfile {
                        userProfileId
                        savedHomes {
                            edges{ node { propertyInfoId}}
                        }
                    }
                    agent {
                        agentId
                    }
                    userProfile {
                        firstName
                        lastName
                        email
                        phoneNumber
                        profilePictureThumbnail
                    }
                }
            }
        `;

const AuthSrvc = {};
AuthSrvc.isLoggedIn = false;
AuthSrvc.isAgent = false;

AuthSrvc.init = () => {
    AuthSrvc.verifyToken();
}

// skip calling API when jwtToken doesn't exist
AuthSrvc.verifyToken = () => {
    const jwtToken = LocalStorageSrvc.getJwtToken();
    if (!jwtToken) {
        AuthSrvc.isLoggedIn = false;
        AuthSrvc.isAgent = false;
        return Promise.resolve();
    }

    return ApiSrvc.makeQuery(verifyTokenQuery(jwtToken))
        .then((payload) => {
            if (payload.errors) {
                return Promise.reject(payload.errors);
            }
            // OK
            AuthSrvc.isLoggedIn = true;
            if (payload.data.verifyToken.agent) {
                AuthSrvc.isAgent = true;
            }
        })
        .catch((e) => {
            if (Array.isArray(e) && e.length > 0) {
                console.error('[AuthSrvc] verifyToken error, logging out, and clear JWT', e[0]);
            }
            AuthSrvc.isLoggedIn = false;
            AuthSrvc.isAgent = false;
            LocalStorageSrvc.clearJwtToken();
        });
}

AuthSrvc.login = (email = '', password = '') => {
    return ApiSrvc.makeQuery(loginQuery(email, password))
        .then((payload) => {
            if (payload.errors) {
                return Promise.reject(payload.errors);
            }
            const jwtToken = payload.data.tokenAuth.token;
            LocalStorageSrvc.setJwtToken(jwtToken);
            // OK
            AuthSrvc.isLoggedIn = true;
            if (payload.data.tokenAuth.agent) {
                AuthSrvc.isAgent = true;
            }
            return Promise.resolve(payload);
        })
        .catch((e) => {
            if (Array.isArray(e) && e.length > 0) {
                console.warn('[AuthSrvc] login error', e[0]);
            }
            return Promise.reject(e[0]);
        });
}

AuthSrvc.logout = () => {
    AuthSrvc.isLoggedIn = false;
    AuthSrvc.isAgent = false;
    LocalStorageSrvc.clearJwtToken();
    window.location.pathname = '';
}
export default AuthSrvc;
