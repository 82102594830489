export class FormValidator {

    constructor(validations) {
        // validations is a list of FieldRuleValidation
        this.validations = validations;
    }

    validate(state) {
        let validationResult = this.createvalidationResult();
        // validate rule
        this.validations.forEach(rule => {
            if (rule.isRequired && !state[rule.fieldName]) {
                validationResult.isCompleteValid = false
                validationResult.result[rule.fieldName].isValid = false;
                validationResult.result[rule.fieldName].errorMessage = "Please fill in the required field"
            }
            if (state[rule.fieldName] && !rule.validationMethod(state[rule.fieldName])) {
                validationResult.isCompleteValid = false
                validationResult.isPartialValid = false
                validationResult.result[rule.fieldName].isValid = false;
                validationResult.result[rule.fieldName].errorMessage = rule.errorMessage;
            }
        });
        return validationResult;
    }

    // create validation object
    createvalidationResult() {
        const validationObject = {}
        this.validations.map(rule => (
            validationObject[rule.fieldName] =
                { isValid: true , errorMessage: '' }
            ));
        return { isPartialValid: true, isCompleteValid: true, result: validationObject };
    }
}

export class FieldRuleValidation {
    constructor(
        fieldName,
        validationMethod,
        isRequired,
        errorMessage = '') {
            this.fieldName = fieldName
            this.validationMethod = validationMethod
            this.isRequired = isRequired
            this.errorMessage = errorMessage
        }
}
