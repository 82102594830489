import React, { Component } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Divider, ExpansionPanelActions } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';

import { AvailableFilters } from './FiltersConstant';

class AdditionalFiltersCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (Array.isArray(this.props.initialData) && prevProps.initialData !== this.props.initialData) {
            const dataToSet = {};
            this.props.initialData.forEach(a => {
                dataToSet[a] = true;
            });
            this.setState({...dataToSet});
        }
    }

    handleCheckboxChange(event) {
        const newVal = event.target.checked;
        this.setState({[event.target.name]: newVal});
        const newState = this.state
        newState[event.target.name] = newVal;
        this.handleApply(newState);
    }

    handleReset() {
        const newState = {...this.state};
        Object.keys(newState).forEach((key) => {
            if (newState[key]) {
                newState[key] = false
            }
        });
        this.setState({...newState});
    }

    handleApply(newState) {
        const newAmenitiesFilter =
            this.props.isCreateMode ? newState : Object.keys(newState).filter(key => !!newState[key]).join(',');
        this.props.handleAmenitiesChange(newAmenitiesFilter);
    }

    render() {
        let that = this;
        let filters = AvailableFilters.map((f) => {
            return (
                <FormControlLabel
                    key={f.name}
                    control={
                        <Checkbox
                            name={f.name}
                            onChange={that.handleCheckboxChange.bind(that)}
                            checked={this.state[f.name] || false}
                            color="primary"
                        />
                    }
                    label={f.label}
                    style={{paddingRight: 8}}
                />
            );
        });
        return (
            <ExpansionPanel className="panel">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Additional Filter</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <FormGroup row>
                        {filters}
                    </FormGroup>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" onClick={this.handleReset.bind(this)}>Reset</Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}
export default AdditionalFiltersCmp;
