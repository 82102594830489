import EnumManagerInstance from './Enum/EnumManager.js';

export function getListingTypeStr(listingType) {
    // listingType ('1')
    // lType ('RENT')
    // return ('RENT')
    return EnumManagerInstance.getListingTypeStr(listingType)
}

export function getPropertyTypeStr(propertyType) {
    // listingType ('1')
    // lType ('CONDO')
    // return ('Condo')
    return EnumManagerInstance.getPropertyTypeStr(propertyType)
}

export function getListingStatusStr(listingStatus) {
    // listingStatus ('1')
    // lType ('ACTIVE')
    // return ('Active')
    return EnumManagerInstance.getListingStatusStr(listingStatus)
}
