import ApiSrvc from '../../service/ApiSrvc';

function buildHelpFindAgentsMutation(name, emailOrPhone, message) {
    const query = `
        mutation findSelectedAgents {
            findSelectedAgents (
                findSelectedAgentsInput: {
                    name: "${name}"
                    emailOrPhone: "${emailOrPhone}"
                    message: "${message}"
            }) {
                ok
            }
        }
    `;
    return query;
}

export function helpFindAgents(name, emailOrPhone, price, bedroom, location, message) {
    return function () {
        const constructedMsg = 'Price: ' + price + ', Bedroom: ' + bedroom + ', Location: ' + location + '. Additional Message: ' + message
        const query = buildHelpFindAgentsMutation(name, emailOrPhone, constructedMsg);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    alert("We'll be in touch with you")
                }
            })
            .catch((error) => {
                console.error(error, "[helpFindAgents] error sending message");
            });
    }
}
