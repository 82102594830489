import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

import { withRouter } from 'react-router';

import AuthSrvc from '../../service/AuthSrvc';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getProfile, logout } from '../../actions/AuthScreen/index.js';

import withWidth from '@material-ui/core/withWidth';

import DrawerCmp from './DrawerCmp/DrawerCmp.js';

import { navList } from './DrawerCmp/DrawerStructure';

import { withTranslation } from 'react-i18next';

function HideOnScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

class ButtonAppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            isCollapsed: false
        }
        this.collapseSidebar.bind(this);

        this.navbarCount = {
            'xs': 3,
            'sm': 4,
            'md': 5,
            'lg': 9
        }
    }

    getButtonSize() {
        return this.props.width === 'xs' || this.props.width === 'sm' ? 'small' : 'large';
    }

    translate(key) {
        return this.props.t('NAVBAR.' + key);
    }

    navbarContent = (list) => list.slice(0, this.navbarCount[this.props.width]).map((item) => {
        const size = this.getButtonSize();
        if (AuthSrvc.isLoggedIn && item.type === 'NOT_AUTHED'){
            return null;
        }
        if (!AuthSrvc.isLoggedIn && item.type === 'AUTHED') {
            return null;
        }
        return (
            <Typography key={item.text} variant="h5" color="inherit">
                <Button fullWidth size={size} color="inherit" onClick={() => this.props.history.push(item.url)}>{this.translate(item.text)}</Button>
            </Typography>
        );
    });

    handleLogOut = () => {
        this.props.logout();
    }

    collapseSidebar = () => {
        this.setState({isCollapsed: false});
    }

    expandSidebar = () => {
        this.setState({isCollapsed: true});
    }

    render() {
        const size = this.getButtonSize();
        const navbarContent = this.navbarContent(navList);
        // Logged In (Agent && User)
        if (AuthSrvc.isLoggedIn) {
            // if AuthSrvc.isLoggedIn = true but authStates.isLoggedIn = false, prefill
            // need the guard to avoid infinite loop (updating states will cause re-render)
            // TODO: move prefill logic outside render method?
            if (!this.props.authStates.isLoggedIn) {
                this.props.getProfile();
            }
        }

        // Logged In (Agent)
        if (AuthSrvc.isLoggedIn && AuthSrvc.isAgent) {
            // placeholder
        }

        return (
            <div className={this.state.classes.root}>
                {/* <span>Width:{this.props.width}</span> */}
                <HideOnScroll {...this.props}>
                    <AppBar>
                        <Drawer open={this.state.isCollapsed} onClose={this.collapseSidebar}>
                            <div role="presentation">
                                <DrawerCmp onClick={this.collapseSidebar} onLogout={this.props.logout} />
                            </div>
                        </Drawer>
                        <Toolbar>
                            <IconButton onClick={this.expandSidebar} className={this.state.classes.menuButton} color="inherit" aria-label="Menu">
                                <MenuIcon />
                            </IconButton>
                            {navbarContent}
                            {AuthSrvc.isLoggedIn &&
                                <Typography variant="h5" color="inherit">
                                    <Button fullWidth size={size} color="inherit" onClick={this.handleLogOut}>Logout</Button>
                                </Typography>
                            }
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authStates: state.authStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        logout,
        getProfile,
    }, dispatch);
}

export default withTranslation()(withWidth()(withRouter(connect(mapStateToProps, matchDispatchToProps)(ButtonAppBar))));
