import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeHomeScreenState } from '../../actions/HomeScreen/index.js';

// Components
import PropertySelectorCmp from '../PropertySelectorCmp/PropertySelectorCmp.js';
import SearchInputCmp from '../SearchInputCmp/SearchInputCmp.js';
import HelpFindAgentsFormCmp from '../HelpFindAgentsFormCmp/HelpFindAgentsFormCmp.js';

// material-ui
import { Grid } from '@material-ui/core';

import Logo from '../../assets/logo/logo_transparent.png';
import './HomeScreen.css';

class HomeScreen extends Component {

    render() {
        return (
                <div class="bg">
                    <Grid container justify="center" style={{ marginTop: 25 }}>
                        <Grid container direction="column" item xs={10} md={4}>
                            <Grid container item justify="center">
                                <img src={Logo} alt="main_image" style={{ height: 300 }}></img>
                            </Grid>
                            <Grid item>
                                <PropertySelectorCmp style={{ paddingBottom: 15 }} />
                            </Grid>
                            <Grid item container>
                                <Grid item xs={12} style={{ paddingBottom: 50 }}>
                                    {
                                        this.props.searchInputStates.isGoogleMapReady ? <SearchInputCmp style={{ paddingBottom: 15 }} /> : 'Loading...'
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid item xs={12} style={{ paddingBottom: 50 }}>
                                    <HelpFindAgentsFormCmp/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.homeStates,
        searchInputStates: state.searchInputStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        changeHomeScreenState
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(HomeScreen);
