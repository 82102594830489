import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';

import ForgotPasswordFormCmp from '../ForgotPasswordFormCmp/ForgotPasswordFormCmp.js';

class ForgotPasswordScreen extends Component {
    render() {
        return (
            <div>
                <Grid container justify="center">
                    <Grid item xs={10} sm={8} md={4}>
                        <ForgotPasswordFormCmp />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ForgotPasswordScreen);
