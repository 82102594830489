import ApiSrvc from '../../service/ApiSrvc';

function buildResetPasswordMutation(email) {
    const query = `
        mutation resetPassword {
            resetPassword (
                resetPasswordInput: {
                    email: "${email}"
            }) {
                ok
            }
        }
    `;
    return query;
}

function buildResetPasswordConfirmMutation(email, newPassword, token) {
    const query = `
        mutation resetPasswordConfirm {
            resetPasswordConfirm (
                resetPasswordConfirmInput: {
                    email: "${email}"
                    newPassword: "${newPassword}"
                    resetPasswordToken: "${token}"
            }) {
                ok
            }
        }
    `;
    return query;
}

export function resetPassword(email) {
    return function () {
        const query = buildResetPasswordMutation(email);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    alert("Check your email to get the reset password link.")
                }
            })
            .catch((error) => {
                console.error(error, "[ResetPassword] error sending request to reset password");
            });
    }
}

export function resetPasswordConfirm(email, newPassword, token) {
    return function () {
        const query = buildResetPasswordConfirmMutation(email, newPassword, token);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    alert("Your password is changed successfully.")
                }
            })
            .catch((error) => {
                console.error(error, "[ResetPassword] error saving new password");
            });
    }
}
