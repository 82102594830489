// React
import React, { Component } from 'react';

// Router
import { BrowserRouter as Router } from 'react-router-dom';

// React - Redux
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

// import reducers
import allReducers from './reducers/index.js';

// import CSS
import './App.css';
import 'react-image-lightbox/style.css';

// import components
import MainCmp from './components/MainCmp/MainCmp.js'

// import service
import LocalStorageSrvc from './service/LocalStorageSrvc.js';
import AuthSrvc from './service/AuthSrvc';

// Theme
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { purple, pink } from '@material-ui/core/colors';

// Create Theme
const theme = createMuiTheme({
    palette: {
        primary: { main: purple[400] },
        secondary: { main: pink['A200']},
    },
});

// Create Redux Store
const store = createStore(allReducers, applyMiddleware(thunk));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readyToRender: false
        }
        LocalStorageSrvc.initJwtToken();
        AuthSrvc.verifyToken()
            .then(() => {
                this.setState({readyToRender: true});
            })
            .catch((e) => {
                console.error('Auth failed', e);
                this.setState({ readyToRender: true });
            });
    }

    render() {
        return (
        <Provider store={store}>
            <Router>
                <ThemeProvider theme={theme}>
                    <MainCmp shouldRender={this.state.readyToRender} />
                </ThemeProvider>
            </Router>
        </Provider>
        );
    }
}

export default App;
