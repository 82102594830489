import { combineReducers } from 'redux';
import HomeScreenReducer from './HomeScreenReducer.js';
import ListingTypeSelectorReducer from './ListingTypeSelectorReducer';
import SearchInputCmpReducer from './SearchInputCmpReducer.js';
import ResultScreenReducer from './ResultScreenReducer';
import AuthScreenReducer from './AuthScreenReducer';
import  FavoriteCmpReducer from './FavoriteCmpReducer';
import AgentDashboardScreenReducer from './AgentDashboardScreenReducer.js';
import PropertyPageReducer from './PropertyPageReducer.js';
import CreateScreenReducer from './CreateScreenReducer.js';
import MessageScreenReducer from './MessageScreenReducer.js';
import CommonReducer from './CommonReducer.js';

const allReducers = combineReducers({
    homeStates: HomeScreenReducer,
    listingTypeSelectorStates: ListingTypeSelectorReducer,
    searchInputStates: SearchInputCmpReducer,
    resultStates: ResultScreenReducer,
    authStates: AuthScreenReducer,
    favoriteStates: FavoriteCmpReducer,
    dashboardStates: AgentDashboardScreenReducer,
    propertyPageStates: PropertyPageReducer,
    createScreenStates: CreateScreenReducer,
    messageScreenStates: MessageScreenReducer,
    commonStates: CommonReducer
});

export default allReducers;
