import React, { Component } from "react";
import Slider from "react-slick";
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../react-slick-additional.css';

import SimilarPropertyThumbCmp from '../SimilarPropertyThumbCmp/SimilarPropertyThumbCmp.js';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className}
            style={{ ...style, display: "block", marginRight: "6%", zIndex: 1, fontSize: "200%" }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1, fontSize: "200%" }}
        onClick={onClick}
        />
    );
}

class SimilarListingsSliderCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
        }
    };

    render() {
        const settings = {
            dots: true,
            arrows: true,
            autoplay: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            ]
        };
        return (
            <Grid container direction="column" item xs={10}>
                {
                    this.props.propertyPageStates.similarPropertyInfos &&
                    this.props.propertyPageStates.similarPropertyInfos > 0 &&
                    <Grid container item justify="center">
                        <Typography variant="h5">
                            Similar Listings
                        </Typography>
                    </Grid>
                }
                <Grid container item justify="center">
                    <Grid item xs={12}>
                        <Slider {...settings}>
                            {
                                this.props.propertyPageStates.similarPropertyInfos
                                && this.props.propertyPageStates.similarPropertyInfos.map((property) =>
                                    <Grid container key={property.propertyInfoId}>
                                        <SimilarPropertyThumbCmp
                                            key={property.propertyInfoId}
                                            data={property}
                                        />
                                    </Grid>)
                            }
                        </Slider>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        propertyPageStates: state.propertyPageStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(SimilarListingsSliderCmp);
