import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';

import ContactUsFormCmp from '../ContactUsFormCmp/ContactUsFormCmp.js';
import { contactUs } from '../../actions/ContactUsScreen/index.js';

class ContactUsScreen extends Component {
    render() {
        return (
            <div>
                <Grid container justify="center">
                    <Grid item xs={10} sm={8} md={4}>
                        <ContactUsFormCmp />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        contactUs
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ContactUsScreen);
