var defaultState = {
    status: false
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case 'CHANGE':
            state.status = !action.payload;
            return state;
        default:
            return state;
    }
}
