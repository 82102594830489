var defaultState = {
    messages: [],
    singleMessageData: {}
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case 'FETCH_ALL_AGENT_MESSAGE':
            return {...state, messages: action.payload};
        case 'FETCH_ALL_AGENT_MESSAGES_BY_PROPERTY_ID':
            return {...state, messages: action.payload};
        case 'FETCH_SINGLE_MESSAGE':
            return { ...state, singleMessageData: action.payload };
        default:
            return state;
    }
}
