import React, {Component} from 'react';
import { Grid, Button , TextField, Typography } from '@material-ui/core';

import { helpFindAgents } from '../../actions/HelpFindAgentsFormCmp/index.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class HelpFindAgentsFormCmp extends Component {
    state = {
        name : '',
        emailOrPhone: '',
        price: '',
        bedroom: '',
        location: '',
        additionalMessage: ''
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.helpFindAgents(this.state.name, this.state.emailOrPhone, this.state.price, this.state.bedroom, this.state.location, this.state.additionalMessage);
    }

    render() {
        return (
            <div>
                <Typography variant="h4">Just relax and let us do the work for you. And it is FREE!</Typography>
                <form onSubmit={this.handleSubmit}>
                    <Grid container item justify="center">
                        <TextField
                            id="name"
                            name="name"
                            autoComplete="name"
                            label="Name"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="outlined-email-input"
                            label="Email or Phone"
                            type="emailOrPhone"
                            name="emailOrPhone"
                            autoComplete="emailOrPhone"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="price"
                            name="price"
                            autoComplete="price"
                            label="Price"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="bedroom"
                            name="bedroom"
                            autoComplete="bedroom"
                            label="Bedroom"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="location"
                            name="location"
                            autoComplete="location"
                            label="Location"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="message"
                            name="additionalMessage"
                            autoComplete="Message"
                            label="Message"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline={true}
                            rows={4}
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={this.handleSubmit.bind(this)}>
                        Find me agents
                    </Button>
                </form>
            </div>
        );
    }
    }

function mapStateToProps(state, ownProps) {
    return {
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        helpFindAgents
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(HelpFindAgentsFormCmp);
