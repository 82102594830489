import React, { Component } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { validateIndonesianPhoneNumber } from '../../util/Validator/FieldValidator.js';

import PropTypes from 'prop-types';
import { FormValidator, FieldRuleValidation } from '../../util/Validator/FormValidator.js';

class AuthAgentFormCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            firstName: undefined,
            lastName: undefined,
            phoneNumber: undefined,
        };
        this.validator =
            new FormValidator(
                [
                    new FieldRuleValidation('firstName', (value) => value, true),
                    new FieldRuleValidation('lastName', (value) => value, true),
                    new FieldRuleValidation('phoneNumber', validateIndonesianPhoneNumber, true),
                ]
            );
        this.validationResult = undefined;
    }

    handleAgentInputChange(event) {
        this.props.handleAgentInputChange(event);
        this.setState({ [event.target.name]: event.target.value });
        // create current state clone since setState is not synchronous
        const newStateToValidate = { ...this.state, [event.target.name]: event.target.value };
        this.validationResult = this.validator.validate(newStateToValidate);
    }

    convertToAgent(event) {
        if (!this.validationResult) {
            this.validationResult = this.validator.validate(this.state)
        }
        if (this.validationResult.isCompleteValid) {
            this.props.convertToAgent(event);
        } else {
            this.props.setAlert('Please fill all fields');
        }
    }

    render() {
        return (
            <div>
                <Grid container item justify="center">
                    <TextField
                        error={ this.validationResult && !this.validationResult.result['firstName'].isValid }
                        id="first-name"
                        name="firstName"
                        label="First Name"
                        className={this.state.classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleAgentInputChange.bind(this)}
                    />
                </Grid>
                <Grid container item justify="center">
                    <TextField
                        error={ this.validationResult && !this.validationResult.result['lastName'].isValid }
                        id="last-name"
                        name="lastName"
                        label="Last Name"
                        className={this.state.classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleAgentInputChange.bind(this)}
                    />
                </Grid>
                <Grid container item justify="center">
                    <TextField
                        error={ this.validationResult && !this.validationResult.result['phoneNumber'].isValid }
                        id="phone-number"
                        name="phoneNumber"
                        label="Phone Number"
                        className={this.state.classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleAgentInputChange.bind(this)}
                        placeholder="+6281xxxxxxxx"
                    />
                </Grid>
                <Grid item container justify="center" style={{paddingTop: 25}}>
                    <Button variant="contained" color="primary" size="large" onClick={this.convertToAgent.bind(this)}>
                        Be an Agent!
                    </Button>
                </Grid>
                {/* TODO: tel number input masking */}
                {/* <Grid container item justify="center">
                    <TextField
                        id="temp-agent-info"
                        label="Agent Info"
                        className={this.state.classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </Grid> */}
            </div>
        );
    }
}

export default AuthAgentFormCmp;
