import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import WhatsappLogo from '../../assets/logo/whatsapp_logo_transparent.png';

import PropTypes from 'prop-types';

class MessageListCmp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: PropTypes.object.isRequired
        }
    }

    // TODO: move to DateUtilSrvc
    convertDate = (date) => {
        const dateObj = (new Date(date));
        const dateString = dateObj.toDateString();
        const timeString = dateObj.toLocaleTimeString();
        return `${dateString} ${timeString}`
    }

    handleSubmit(propertyInfoId) {
        const phoneNumber = "16504477788";
        var textParam = "text=" + window.location.origin + "/property/" + propertyInfoId + "  Hello, reaching out about your interest on this property."
        const whatsappUri = decodeURI("https://wa.me/" + phoneNumber + "?" + textParam)
        window.open(whatsappUri);
    }

    render() {
        return (
            <Paper style={{width: "100%"}}>
                <Grid item container justify="center">
                    <Table className={this.state.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Time</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.messages.map(m => (
                                <TableRow
                                    hover
                                    key={m.node.agentMessageId}
                                    onClick={() => this.props.handleMessageClick(m.node.agentMessageId)}
                                    style={{cursor: "pointer"}}>
                                    <TableCell align="left" style={{ fontWeight: m.node.read? 'normal' : 'bold'}}>{this.convertDate(m.node.createdAt) }</TableCell>
                                    <TableCell align="left" style={{ fontWeight: m.node.read? 'normal' : 'bold'}}>{m.node.clientEmail}</TableCell>
                                    <TableCell align="left" style={{ fontWeight: m.node.read? 'normal' : 'bold'}}>{m.node.clientName}</TableCell>
                                    <TableCell align="left" style={{ fontWeight: m.node.read? 'normal' : 'bold'}}>
                                        <Grid><img src={WhatsappLogo} alt="whatsapp_logo"
                                            style={{ height:30, cursor: "pointer" }}
                                            onClick={this.handleSubmit.bind(this, m.node.propertyInfoId)}/>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Paper>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        messageScreenStates: state.messageScreenStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(MessageListCmp);
