import LocalStorageSrvc from '../../service/LocalStorageSrvc';
import ApiSrvc from '../../service/ApiSrvc';
import AuthSrvc from '../../service/AuthSrvc';
import { sanitizeIndonesianPhoneNumber } from '../../util/Validator/FieldValidator.js';

export function switchToSignUp(resp) {
    return {
        type: 'SWITCH_SIGNUP_FORM',
        payload: resp
    };
}

export function switchToLogIn(resp) {
    return {
        type: 'SWITCH_LOGIN_FORM',
        payload: resp
    }
}

export function switchToNormalUser(resp) {
    return {
        type: 'SWITCH_TO_NORMAL_USER',
        payload: resp
    }
}

export function switchToAgent(resp) {
    return {
        type: 'SWITCH_TO_AGENT',
        payload: resp
    }
}

export function updateLoginData(resp) {
    return {
        type: 'UPDATE_LOGIN_DATA',
        payload: resp
    }
}

export function resetLoginData(resp) {
    return {
        type: 'RESET_LOGIN_DATA',
        payload: resp
    }
}

export function errorLoginData(errMessage) {
    return {
        type: 'ERROR_LOGIN_DATA',
        payload: errMessage
    }
}

export function postTryToLogin(token, userProfileId = '', agentId = '', userEmail = '', firstName = '', lastName = '', phoneNumber = '', profilePictureThumbnail = '', favoritePropertiesArr = []) {
    return {
        type: 'VERIFIED_LOGIN',
        payload: { token, userProfileId, agentId, userEmail, firstName, lastName, phoneNumber, profilePictureThumbnail, favoritePropertiesArr }
    }
}

export function logout() {
    AuthSrvc.logout();
    return {
        type: 'LOGOUT',
    }
}

export function tryLogin() {
    return function (dispatch, getState) {
        const { authStates } = getState();
        // TODO: handle refresh page
        // const existingAuthToken = localStorage.getItem('krumaku_auth_token');
        // if (existingAuthToken) {
        //     dispatch(postTryToLogin(existingAuthToken));
        //     return;
        // }
        if (!authStates.email && !authStates.password) {
            return;
        }
        return AuthSrvc.login(authStates.email, authStates.password)
            .then(function (response) {
                // OK
                const data = response.data.tokenAuth;
                const userProfile = data.userProfile;
                const agentId = data.agent && data.agent.agentId;
                const favoritePropertiesArr = userProfile.savedHomes.edges.map(prop => prop.node);
                dispatch(
                    postTryToLogin(
                        data.token,
                        userProfile.userProfileId,
                        agentId,
                        userProfile.email,
                        userProfile.firstName,
                        userProfile.lastName,
                        userProfile.phoneNumber,
                        userProfile.profilePictureThumbnail,
                        favoritePropertiesArr));
            })
            .catch((error) => {
                if (error) {
                    dispatch(errorLoginData(error.message))
                    return;
                }
                console.error({error, authStates}, "[Auth] error logging in");
            });
    }
}

export function trySignUp() {
    return (dispatch, getState) => {
        const { authStates } = getState();
        let agentQuery = '';
        if (!authStates.email && !authStates.password) {
            return;
        }
        const query = `
            mutation signUp {
                signUp(
                    userInput: {
                        email: "${authStates.email}",
                        password: "${authStates.password}"
                    },
                    ${agentQuery}
                )
                {
                    ok
                    userProfile {
                        userProfileId
                        firstName
                        lastName
                        email
                        phoneNumber
                    }
                    agent {
                        agentId
                    }
                    token
                }
            }
        `;
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (response.errors) {
                    dispatch(errorLoginData(response.errors[0].message))
                    return;
                }
                AuthSrvc.isLoggedIn = true;
                // OK
                const data = response.data.signUp;
                const userProfile = data.userProfile;
                const agentId = data.agent && data.agent.agentId;
                LocalStorageSrvc.setJwtToken(data.token);
                dispatch(
                    postTryToLogin(
                        data.token,
                        userProfile.userProfileId,
                        agentId,
                        userProfile.email,
                        userProfile.firstName,
                        userProfile.lastName,
                        userProfile.phoneNumber,
                        userProfile.profilePictureThumbnail));
            })
            .catch((error) => {
                console.error({authStates, error}, "[Auth] error signing up");
            });
    }
}

export function getProfile () {
    return (dispatch, getState) => {
        const query = `
            query userData {
                userData {
                    __typename
                    ... on UserProfileNode {
                        userProfileId
                        firstName
                        lastName
                        email
                        phoneNumber
                        profilePictureThumbnail
                        savedHomes {
                            edges{ node { propertyInfoId}}
                        }
                    }
                    ... on AgentNode {
                        agentId
                    }
                }
            }
        `;
        return ApiSrvc.makeQuery(query)
            .then((response) => {
                const userProfile = response.data.userData[1];
                const agentId = response.data.userData[2] && response.data.userData[2].agentId;
                const favoritePropertiesArr = response.data.userData[1].savedHomes.edges.map(prop => prop.node);
                const token = LocalStorageSrvc.getJwtToken();
                dispatch(
                    postTryToLogin(
                        token,
                        userProfile.userProfileId,
                        agentId,
                        userProfile.email,
                        userProfile.firstName,
                        userProfile.lastName,
                        userProfile.phoneNumber,
                        userProfile.profilePictureThumbnail,
                        favoritePropertiesArr));
            })
            .catch((error) => {
                console.error(error, "[Auth] error getting profile");
            });
    }
}

export function convertToAgent(agentInfo) {
    return (dispatch, getState) => {
        const query = `
            mutation signUp {
                signUp(
                    agentInput: {
                        firstName: "${agentInfo.firstName}",
                        lastName: "${agentInfo.lastName}",
                        phoneNumber: "${agentInfo.phoneNumber}"
                    }) {
                    ok
                    userProfile {
                        userProfileId
                        email
                        firstName
                        lastName
                        phoneNumber
                    }
                    agent {
                        agentId
                    }
                }
            }
        `;
        return ApiSrvc.makeQuery(query)
            .then((response) => {
                // OK
                const data = response.data.signUp;
                const userProfile = data.userProfile;
                const agentId = data.agent && data.agent.agentId;
                const token = LocalStorageSrvc.getJwtToken();
                // re-init
                AuthSrvc.init()
                dispatch(
                    postTryToLogin(
                        token,
                        userProfile.userProfileId,
                        agentId,
                        userProfile.email,
                        userProfile.firstName,
                        userProfile.lastName,
                        userProfile.phoneNumber,
                        userProfile.profilePictureThumbnail));
            })
            .catch((error) => {
                console.error(error, "[Auth] error converting to agent");
            });
    }
}

export function profilePictureChanged(response) {
    return {
        type: 'PROFILE_PICTURE_CHANGED',
        payload: { profilePictureThumbnail: response.data.uploadProfilePicture.profilePictureThumbnail }
    };
}

const uploadPictureQuery = (variableName) =>
    `mutation uploadProfilePicture(
        $${variableName}: Upload!)
            {
                uploadProfilePicture(
                    ${variableName}: $${variableName}) {
                        ok,
                        profilePictureThumbnail
                    }
            }`;

export function handleChangeProfilePicture(profilePictureInput) {
    return function (dispatch) {
        const imageVariable = "profilePicture";
        const query = uploadPictureQuery(imageVariable);
        return ApiSrvc.makeMultipartQuery(query, imageVariable, profilePictureInput)
            .then(function (response) {
                if (!response.errors) {
                    console.log('Profile Picture Changed', response);
                    dispatch(profilePictureChanged(response));
                }
            })
            .catch((error) => {
                console.error(error, "[changeProfilePicture] error saving picture");
            });
    }
}

function userInfoChanged(response) {
    return {
        type: 'USER_PROFILE_INFO_CHANGED',
        payload: response.data.updateUserProfile.userProfile
    };
}

const editUserProfileQuery = (userProfileInput) =>
    `mutation updateUserProfile {
        updateUserProfile(
            userProfileInput: {
                ${ userProfileInput.firstName ? ('firstName: "' + userProfileInput.firstName + '"' ) : "" }
                ${ userProfileInput.lastName ? ('lastName: "' + userProfileInput.lastName + '"' ) : "" }
                ${ userProfileInput.phoneNumber ? ('phoneNumber: "' + sanitizeIndonesianPhoneNumber(userProfileInput.phoneNumber) + '"' ) : "" }
            },
        )
        {
            ok
            userProfile {
                firstName
                lastName
                email
                phoneNumber
            }
        }
    }`;

export function editUserProfile(userProfileInput) {
    return function (dispatch) {
        const query = editUserProfileQuery(userProfileInput);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('User Profile Changed', response);
                    dispatch(userInfoChanged(response));
                }
            })
            .catch((error) => {
                console.error(error, "[editUserProfile] error editing user profile");
            });
    }
}