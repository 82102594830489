import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Grid, Button } from '@material-ui/core';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

import { setAlert } from '../../actions/Common/index.js';
import {
    switchToSignUp,
    switchToLogIn,
    switchToNormalUser,
    switchToAgent,
    updateLoginData,
    tryLogin,
    trySignUp
} from '../../actions/AuthScreen/index.js';

class AuthBtnGroupsCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired
        }
    }

    handleSignUp = () => {
        this.props.switchToSignUp();
    }

    handleLogIn = () => {
        this.props.switchToLogIn();
    }

    handleSignUpSubmit = () => {
        if (this.props.canSubmit) {
            this.props.updateLoginData(this.props.currentState);
            this.props.trySignUp();
        } else {
            this.props.setAlert("Please fill all fields correctly");
        }
    }

    handleLogInSubmit = () => {
        if (this.props.canSubmit) {
            this.props.updateLoginData(this.props.currentState);
            this.props.tryLogin();
        } else {
            this.props.setAlert("Please fill all fields correctly");
        }
    }

    render() {
        const isSignUpForm = this.props.isSignUpForm;
        let btnGroups;

        function getBtn(str, onClickFn, classes) {
            return (
                <Button fullWidth variant="contained" color="primary" size="medium" onClick={onClickFn} className={classes.button}>
                    {str}
                </Button>
            );
        }

        if (isSignUpForm) {
            btnGroups = (
                <Grid container item direction="column" alignItems="center" spacing={2} style={{ padding: 10 }}>
                    <Grid container item xs={12} justify="center">
                        {getBtn('Submit', this.handleSignUpSubmit, this.state.classes)}
                    </Grid>
                    <Grid container item xs={12} justify="center">
                        {getBtn('Have an account?', this.handleLogIn, this.state.classes)}
                    </Grid>
                </Grid>
            );
        } else {
            btnGroups = (
                <Grid container justify="center" spacing={8} style={{ padding: 10 }}>
                    <Grid item xs={6}>
                        {getBtn('Login', this.handleLogInSubmit, this.state.classes)}
                    </Grid>
                    <Grid item xs={6}>
                        {getBtn('Sign Up', this.handleSignUp, this.state.classes)}
                    </Grid>
                </Grid>
            )
        }
        return (
            <div>
                {btnGroups}
            </div>
        );
    }
};

function mapStateToProps(state) {
    return {
        authStates: state.authStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        switchToSignUp,
        switchToLogIn,
        switchToNormalUser,
        switchToAgent,
        updateLoginData,
        tryLogin,
        trySignUp,
        setAlert
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(AuthBtnGroupsCmp));
