import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';

import ChangePasswordFormCmp from '../ChangePasswordFormCmp/ChangePasswordFormCmp.js';

class ChangePasswordScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'token': props.match.params.token
        }
    }

    render() {
        return (
            <div>
                <Grid container justify="center">
                    <Grid item xs={10} sm={8} md={4}>
                        <ChangePasswordFormCmp token={this.state.token}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ChangePasswordScreen);
