import ApiSrvc from '../../service/ApiSrvc';

const myListingQuery = `
    query {
        myPropertyInfos {
            edges {
                node {
                    properties {
                        propertyInfoId
                        price
                        listingType
                        propertyType
                        listingStatus
                        formattedAddress
                        bedroom
                        bathroom
                        propertypictureSet {
                            propertyPictureId
                            thumbnail
                            original
                        }
                        amenities
                    }
                }
            }
        }
    }
`;

const changeListingStatusQuery = (propId, listingStatus) =>
`mutation changeListingStatus {
        changeListingStatus(
            propertyInfoId: "${propId}",
            newListingStatus: ${listingStatus}) {
                ok
            }
    }`;

export function postFetchMyListing(response) {
    return {
        type: 'FETCH_MY_LISTING',
        payload: response.map((obj) => obj.node.properties) || []
    }
}

export function getMyListing() {
    return function (dispatch, getState) {
        return ApiSrvc.makeQuery(myListingQuery)
            .then(function (response) {
                if (!response.errors) {
                    const data = response.data.myPropertyInfos.edges;
                    dispatch(postFetchMyListing(data));
                }
            })
            .catch((error) => {
                console.error(error, "[agentDashboard] error fetching my listing");
            });
    }
}

export function changeListingStatus(propId, listingStatus) {
    return function () {
        return ApiSrvc.makeQuery(changeListingStatusQuery(propId, listingStatus))
            .then(function (response) {})
            .catch((error) => {
                console.error(error, "[agentDashboard] error change listing status");
            });
    }
}
