import ApiSrvc from '../../service/ApiSrvc';

const getAllMessageQuery = (messageFilterInput) => `
    query{
        allAgentMessages
            ${messageFilterInput ?
                '(' +
                    (messageFilterInput.messageId ? 'agentMessageIds: "' + messageFilterInput.messageId + '"' : '') +
                    (messageFilterInput.propertyInfoId ? 'propertyInfoIds: "' + messageFilterInput.propertyInfoId + '"' : '') +
                ')'
                : ''}
        {
            edges {
                node {
                    agentMessageId
                    createdAt
                    clientName
                    clientEmail
                    clientMessage
                    clientPhone
                    propertyInfoId
                    propertyFormattedAddress
                    read
                }
            }
        }
    }
`;

const readSingleMessageQuery = (messageId) => `
    mutation markAgentMessageAsRead {
        markAgentMessageAsRead(agentMessageId: "${messageId}") {
            ok
        }
    }

`;

export function postFetchAllMessage(response) {
    return {
        type: 'FETCH_ALL_AGENT_MESSAGE',
        payload: response
    };
}

export function postFetchMessagesByPropertyInfoId(response) {
    return {
        type: 'FETCH_ALL_AGENT_MESSAGES_BY_PROPERTY_ID',
        payload: response
    };
}

export function postFetchSingleMessage(response) {
    return {
        type: 'FETCH_SINGLE_MESSAGE',
        payload: response
    };
}

export function getAllMessage() {
    return (dispatch, getState) => {
        return ApiSrvc.makeQuery(getAllMessageQuery())
            .then(function (response) {
                console.log('messages: ', response);
                dispatch(postFetchAllMessage(response.data.allAgentMessages.edges));
            })
            .catch((error) => {
                console.error(error, "[MessageScreen] error fetching agent's message");
            });
    }
}

export function getMessagesByPropertyId(propertyInfoId) {
    return (dispatch, getState) => {
        return ApiSrvc.makeQuery(getAllMessageQuery({propertyInfoId: propertyInfoId}))
            .then(function (response) {
                console.log('messages by propertyInfoId: ', response);
                dispatch(postFetchMessagesByPropertyInfoId(response.data.allAgentMessages.edges));
            })
            .catch((error) => {
                console.error(error, "[Property messages analytics] error fetching agent's message");
            });
    }
}

export function getSingleMessage(messageId) {
    return (dispatch, getState) => {
        return ApiSrvc.makeQuery(getAllMessageQuery({messageId: messageId}))
            .then(function (response) {
                console.log('single message: ', response);
                dispatch(postFetchSingleMessage(response.data.allAgentMessages.edges[0].node));
            })
            .catch((error) => {
                console.error(error, "[MessageScreen] error fetching agent's message");
            });
    }
}

export function readSingleMessage(messageId) {
    return (dispatch, getState) => {
        return ApiSrvc.makeQuery(readSingleMessageQuery(messageId))
            .then(function (response) {
                console.log('read message: ', response);
            })
            .catch((error) => {
                console.error(error, "[MessageScreen] error marking message read");
            });
    }
}
