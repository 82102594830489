import React, { Component } from 'react';
import classNames from 'classnames';

import { Grid, Button, Typography, Card, CardMedia, Chip } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import DeleteIcon from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';

import FavoriteCmp from '../FavoriteCmp/FavoriteCmp.js';
import RequestPropertyInfoFormCmp from '../RequestPropertyInfoFormCmp/RequestPropertyInfoFormCmp.js';
import SimilarListingsSliderCmp from '../SimilarListingsSliderCmp/SimilarListingsSliderCmp.js';
import BasicPropertyInfoCmp from '../BasicPropertyInfoCmp/BasicPropertyInfoCmp';

import { fetchPropertyRelatedInfo, fetchPropertyById, fetchSimilarPropertyInfos, prefillPropertyData } from '../../actions/PropertyPageScreen/index.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthSrvc from '../../service/AuthSrvc';

import Logo from '../../assets/logo/logo_transparent.png';

import { AvailableFiltersMap } from '../AdditionalFiltersCmp/FiltersConstant';

class PropertyInfoScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: PropTypes.object.isRequired,
            currentPropertyId: '',
            propertyData: undefined,
            isPicturePreviewOpened: false,
            thumbIndex: 0,
            isLoggedIn: AuthSrvc.isLoggedIn
        }
        // since /property/{id} id is required, when there is no id, router will fallback to home page
        if (this.props.match.params.id) {
            // reset scroll
            window.scrollTo(0, 0);
            this.state.currentPropertyId = this.props.match.params.id;
            this.initPropertyData(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps) {
        // detect propertyId change in the URL
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({currentPropertyId: this.props.match.params.id});
            // WARNING: this.state.currentPropertyId will not be reflected right away
            // so don't use this.state.currentPropertyId until componentDidUpdate finished
            this.initPropertyData(this.props.match.params.id);
        }
    }

    // if propId is defined, it is called from constructor, otherwise its from componentDidUpdate
    initPropertyData(propId = '') {
        let currentPropertyId = propId;
        const previousRouterState = this.props.location.state || {};
        if (Object.keys(previousRouterState).length > 0) {
            // possibly redirected from search page, check passed state
            const propertyData = previousRouterState.propertyData;
            currentPropertyId = propertyData.propertyInfoId;
            this.props.prefillPropertyData(propertyData);
        } else {
            this.props.fetchPropertyById(propId);
        }
        // TODO: on error fetching/displaying property data, we should go back to homepage

        this.props.fetchSimilarPropertyInfos(currentPropertyId);
        // only request for further if user is logged in
        if (AuthSrvc.isLoggedIn) {
            this.props.fetchPropertyRelatedInfo(currentPropertyId);
        }
    }

    handleGoBack = () => {
        // new browser (previous url is blank)
        if (this.props.history.length === 2) {
            this.props.history.push('/result');
        } else {
            this.props.history.goBack();
        }
    }

    componentDidMount() {
        window.addthis.init();
        window.addthis.toolbox();
        if (typeof window.addthis.layers.refresh === "function") {
            window.addthis.layers.refresh();
        }
    }

    getAmenitiesDom(amenities) {
        let filters = amenities.map(amenity => {
            return (
                <Chip
                    color="primary"
                    label={AvailableFiltersMap[amenity]}
                    variant="outlined"
                    style={{marginRight: 5}}
                />
            );
        });
        return filters;
    }

    render() {
        const { thumbIndex }  = this.state;
        const { propertyData } = this.props.propertyPageStates;
        const agentInfo = propertyData && propertyData.userProfile;
        const shouldRenderLightbox = Array.isArray(propertyData.propertypictureSet) && propertyData.propertypictureSet.length > 0;
        let agentInfoCmp;
        if (agentInfo) {
            let agentProfilePictureThumbnail;
            if (agentInfo.profilePictureThumbnail) {
                agentProfilePictureThumbnail = agentInfo.profilePictureThumbnail;
            } else {
                agentProfilePictureThumbnail = Logo;
            }
            agentInfoCmp = (
                <Grid container direction="column" alignItems="center" style={{margintTop: 15}}>
                    <Grid item>
                        <Typography variant="h6">Property Agent</Typography>
                    </Grid>
                    <Grid item style={{marginTop: 15}}>
                        <img alt="agent_profile_picture"
                            src={agentProfilePictureThumbnail}
                            style={{ height: 150, width: 150, borderRadius: "50%" }}></img>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" style={{ marginLeft: 10 }}>{agentInfo.firstName + ' ' + agentInfo.lastName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" style={{ marginLeft: 10 }}>{agentInfo.phoneNumber || '-'}</Typography>
                    </Grid>
                </Grid>
            );
        } else {
            agentInfoCmp = (
                <Grid container direction="column" alignItems="center" style={{ margintTop: 15 }}>
                    <Grid item>
                        <Typography variant="h6">Contact Agent</Typography>
                    </Grid>
                </Grid>
            )
        }
        let mainImage;
        if (propertyData && propertyData.propertypictureSet && propertyData.propertypictureSet[0] && propertyData.propertypictureSet[0].original) {
            mainImage = propertyData.propertypictureSet[0].original
        } else {
            mainImage = Logo
        }
        return (
            <div>
                {shouldRenderLightbox && this.state.isPicturePreviewOpened && (
                    <Lightbox
                        mainSrc={propertyData.propertypictureSet[thumbIndex].original}
                        nextSrc={propertyData.propertypictureSet[(thumbIndex + 1) % propertyData.propertypictureSet.length].original}
                        prevSrc={propertyData.propertypictureSet[(thumbIndex + propertyData.propertypictureSet.length - 1) % propertyData.propertypictureSet.length].original}
                        onCloseRequest={() => this.setState({ isPicturePreviewOpened: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                thumbIndex: (thumbIndex + propertyData.propertypictureSet.length - 1) % propertyData.propertypictureSet.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                thumbIndex: (thumbIndex + propertyData.propertypictureSet.length + 1) % propertyData.propertypictureSet.length,
                            })
                        }
                    />
                )}
                <Grid container justify="center" style={{paddingTop: 25}}>
                    <Grid container item xs={10} md={6} direction="column" style={{marginLeft: 15, marginRight: 15, marginBottom: 25}}>
                        <Grid item>
                            <Button onClick={this.handleGoBack.bind(this)} color="primary" variant="outlined" size="medium" className={this.state.classes.button} style={{ marginBottom: 25 }}>
                                <DeleteIcon className={classNames(this.state.classes.leftIcon, this.state.classes.iconSmall)} />
                                Go Back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Card style={{ width: "100%" }}>
                                <CardMedia
                                    style={{ height: 400, backgroundSize: "contain", cursor: "pointer" }}
                                    onClick={() => this.setState({isPicturePreviewOpened: true})}
                                    image={mainImage}
                                    title="Icon Placeholder"
                                />
                            </Card>
                        </Grid>
                        <Grid item>
                            <FavoriteCmp
                                propertyInfoId={this.state.currentPropertyId}
                                propertyData={propertyData}
                            />
                        </Grid>
                        <Grid item className="addthis_inline_share_toolbox"
                            data-url={window.location.href}
                            data-title="Check this interesting property!"
                            style={{ display: 'none' }}>
                        </Grid>
                        <BasicPropertyInfoCmp data={propertyData}></BasicPropertyInfoCmp>
                        { propertyData.description &&
                            <Grid item style={{ marginTop: 10 }}>
                                Description: {propertyData.description}
                            </Grid>
                        }
                        { propertyData.minLeaseDurationMonth &&
                            <Grid item style={{ marginTop: 10 }}>
                                Minimum lease duration: {propertyData.minLeaseDurationMonth} month(s)
                            </Grid>
                        }
                        { propertyData.securityDeposit &&
                            <Grid item style={{ marginTop: 10 }}>
                                Security deposit: Rp. {propertyData.securityDeposit}
                            </Grid>
                        }
                        { propertyData.leaseTerms &&
                            <Grid item style={{ marginTop: 10 }}>
                                Lease terms: {propertyData.leaseTerms}
                            </Grid>
                        }
                        { propertyData.amenities &&
                            <Grid item container style={{ marginTop: 10 }}>
                                {this.getAmenitiesDom(propertyData.amenities)}
                            </Grid>
                        }
                    </Grid>
                    <Grid container item direction="column" xs={12} md={3}>
                        <Grid container item justify="center">
                            {agentInfoCmp}
                        </Grid>
                        {
                            this.state.currentPropertyId && (
                                <Grid item style={{ marginTop: 10 }}>
                                    <RequestPropertyInfoFormCmp
                                        isInfoRequested={this.props.propertyPageStates.isInfoRequested[this.state.currentPropertyId]}
                                        propertyInfoId={this.state.currentPropertyId}
                                        style={{ paddingBottom: 15 }} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid container item style={{ marginTop: 45 }} justify="center">
                    <SimilarListingsSliderCmp/>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        propertyPageStates: state.propertyPageStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPropertyRelatedInfo,
        fetchPropertyById,
        fetchSimilarPropertyInfos,
        prefillPropertyData
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PropertyInfoScreen);
