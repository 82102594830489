import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

import { withRouter } from 'react-router';

import MessageScreen from '../MessageScreen/MessageScreen.js';
import UserProfileScreen from '../UserProfileScreen/UserProfileScreen.js';
import FavoriteScreen from '../FavoriteScreen/FavoriteScreen.js';
import AgentDashboardScreen from '../AgentDashboardScreen/AgentDashboardScreen.js';
import CreatePropertyScreen from '../CreatePropertyScreen/CreatePropertyScreen.js';
import AuthSrvc from '../../service/AuthSrvc';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

class NavTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: {
                tabStyle: {
                    backgroundColor: 'orange'
                }
            },
            tabPosition: 0
        }

        if (this.props.match && this.props.match.params.type) {
            const index = this.tabOrder.indexOf(this.props.match.params.type);
            if (index !== -1) {
                this.state.tabPosition = index;
            } else {
                this.props.history.replace('/dash')
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.type !== this.props.match.params.type) {
            const index = this.tabOrder.indexOf(this.props.match.params.type);
            if (index !== -1) {
                this.setState({tabPosition: index});
            } else {
                this.props.history.replace('/dash')
            }
        }
    }

    // used for url
    tabOrder = ['profile', 'favorites', 'messages', 'listing', 'create'];

    setValue = (newValue) => {
        if (this.tabOrder[newValue]) {
            this.props.history.push(`/dash/${this.tabOrder[newValue]}`);
            this.setState({ tabPosition: newValue });
        }
    }

    handleChange = (event, newValue) => {
        this.setValue(newValue);
    }

    render() {
        const isMobile = this.props.width === 'xs' || this.props.width === 'sm';
        return (
            <div>
                { !isMobile &&
                    <AppBar position="static">
                        <Tabs variant="fullWidth" value={this.state.tabPosition} onChange={this.handleChange}>
                            <LinkTab label="Profile" />
                            <LinkTab label="Favorites" />
                            {AuthSrvc.isAgent && <LinkTab label="Messages" />}
                            {AuthSrvc.isAgent && <LinkTab label="My Listings" />}
                            {AuthSrvc.isAgent && <LinkTab label="Create Listing" />}
                        </Tabs>
                    </AppBar>
                }
                {this.state.tabPosition === 0 && <TabContainer><UserProfileScreen></UserProfileScreen></TabContainer>}
                {this.state.tabPosition === 1 && <TabContainer><FavoriteScreen></FavoriteScreen></TabContainer>}
                {AuthSrvc.isAgent && this.state.tabPosition === 2 && <TabContainer><MessageScreen></MessageScreen></TabContainer>}
                {AuthSrvc.isAgent && this.state.tabPosition === 3 && <TabContainer><AgentDashboardScreen></AgentDashboardScreen></TabContainer>}
                {AuthSrvc.isAgent && this.state.tabPosition === 4 && <TabContainer><CreatePropertyScreen></CreatePropertyScreen></TabContainer>}
                {/* {value === 2 && <TabContainer>Page Three</TabContainer>} */}
            </div>
        )
    }
}

export default withWidth()(withRouter(NavTabs));
