var defaultState = {
    status: false,
    isSaved: false,
    isUploading: false,
    hasError: false,
    toEditProperty: {}
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case 'PROPERTY_SAVED':
            return {...state, isSaved: true, toEditProperty: action.payload}
        case 'PROPERTY_PICTURE_UPDATED':
            return { ...state, isSaved: true}
        case 'UPLOADING_PICTURE':
            return {...state, isUploading: true }
        case 'PROPERTY_PICTURE_UPLOADED':
            return { ...state, isUploading: false, toEditProperty: action.payload }
        case 'PROPERTY_PICTURE_DELETED':
            return {...state, toEditProperty: action.payload}
        case 'PROPERTY_PREFILLED':
            return {...state, toEditProperty: action.payload}
        case 'SAVING_PROPERTY':
            return {...state, isSaved: false }
        case 'PREFILL_PROPERTY':
            return { ...state }
        case 'RESET_STATE':
            return defaultState;
        default:
            return state;
    }
}
