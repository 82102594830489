import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Grid, Button } from '@material-ui/core';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import classNames from 'classnames';
import MessageListCmp from '../MessageListCmp/MessageListCmp.js';
import MessageInfoCmp from '../MessageInfoCmp/MessageInfoCmp.js';
import { getMessagesByPropertyId } from '../../actions/MessageScreen/index.js';

import PropTypes from 'prop-types';

class PropertyAnalyticsDashboardScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            switchToMessageInfo: false,
            selectedMessageId: '',
            selectedMessageData: {},
            propertyId: '',
            classes: PropTypes.object.isRequired
        }
    }

    handleMessageClick(messageId) {
        this.goToMessageInfo(messageId);
    }

    handleBackButton() {
        if (this.state.selectedMessageId) {
            this.goToPropertyAnalyticsDashboard();
            this.props.history.push(`/dash/property_analytics/${this.state.propertyId}`);
        } else {
            // go back to list of listings
            this.props.history.push('/dash/listing');
        }
    }

    goToMessageInfo = (messageId) => {
        this.setState({ selectedMessageId: messageId, switchToMessageInfo: true });
    }

    goToPropertyAnalyticsDashboard() {
        this.setState({ selectedMessageId: '', switchToMessageInfo: false });
    }

    componentDidUpdate(prevProps, prevState) {
        // detect propertyId change in the URL
        if (prevState.propertyId !== this.props.match.params.id) {
            this.props.getMessagesByPropertyId(this.props.match.params.id);
        }
    }

    componentDidMount() {
        this.setState({propertyId: this.props.match.params.id});
    }

    render() {
        return (
            <div>
                <Grid container direction="column">
                    <Grid item style={{height: 50, marginTop: 20}}>
                        <Grid item container justify="center">
                            <Grid item xs={10} md={8}>
                                <Button onClick={this.handleBackButton.bind(this)} color="primary" variant="outlined" size="medium" className={this.state.classes.button} style={{ marginBottom: 25 }}>
                                    <BackIcon className={classNames(this.state.classes.leftIcon, this.state.classes.iconSmall)} />
                                    Back
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item container justify="center">
                            <Grid item xs={12} md={8}>
                                {
                                    !this.state.switchToMessageInfo &&
                                    <MessageListCmp
                                        messages={this.props.messageScreenStates.messages}
                                        handleMessageClick={this.handleMessageClick.bind(this)}
                                    />
                                }
                                {
                                    this.state.switchToMessageInfo &&
                                    <MessageInfoCmp messageId={this.state.selectedMessageId} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        messageScreenStates: state.messageScreenStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getMessagesByPropertyId,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(PropertyAnalyticsDashboardScreen));
