import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

class MapScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            map: undefined,
            circle: undefined,
            isGoogleMapReady: false
        }
    }

    componentDidMount() {
        if (this.props.searchInputStates.isGoogleMapReady && this.props.resultStates.propertyResultData) {
            this.initGoogleMap();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // handle initializing google when it is not triggered by componentDidMount
        if (!prevProps.searchInputStates.isGoogleMapReady && this.props.searchInputStates.isGoogleMapReady) {
            this.initGoogleMap();
        }
        // initialize map when it's not present yet and we have all the required data
        if (this.state.map && !this.state.circle && this.props.searchInputStates.searchQuery.lat && this.props.searchInputStates.searchQuery.lng) {
            this.initGoogleMapCircle();
        }
        if (this.props.searchInputStates.searchQuery.lat &&
            this.props.searchInputStates.searchQuery.lng &&
            prevProps.searchInputStates.searchQuery.lat !== this.props.searchInputStates.searchQuery.lat &&
            prevProps.searchInputStates.searchQuery.lng !== this.props.searchInputStates.searchQuery.lng) {
                this.setNewMapCenter(this.props.searchInputStates.searchQuery.lat, this.props.searchInputStates.searchQuery.lng)
        } else if (this.shouldUpdateMarker(prevProps, this.props)) {
            this.removeAllMarkers()
            this.addMarker();
        } else if (this.state.markers.length === 0 && this.props.resultStates.propertyResultData) {
            // this state is for the very first map creation at the page. Or going back to result screen page.
            this.addMarker();
        }
    }

    shouldUpdateMarker(prevProps, currentProps) {
        // check if list of properties has changed or not.
        const prevPropIds = prevProps.resultStates.propertyResultData.map((prop) => prop.propertyInfoId);
        const currentPropsIds = currentProps.resultStates.propertyResultData.map((prop) => prop.propertyInfoId);
        if (currentPropsIds.length !== prevPropIds.length) {
            return true;
        }
        for (let i = 0; i < currentPropsIds.length; ++i) {
            if (currentPropsIds[i] !== prevPropIds[i]) return true;
        }
        return false;
    }

    setNewMapCenter(newLat, newLng) {
        if (this.state.circle) {
            this.state.circle.setCenter({lat: newLat, lng: newLng})
            this.state.map.setCenter({lat: newLat, lng: newLng})
        }
    }

    initGoogleMap() {
        const areaSelected = {
            lat: this.props.searchInputStates.searchQuery.lat || -6.2443916,
            lng: this.props.searchInputStates.searchQuery.lng || 106.77654430000007
        };
        // The map (API is changed on every 'new')
        // TODO: memoize it on parent props (don't re-init if its already init before)
        //       (i.e. if user navigate away from /result page)
        /*global google*/
        const map = new google.maps.Map(
            document.getElementById('map'), { zoom: 12, center: areaSelected });
        this.setState({map: map})
    }

    initGoogleMapCircle() {
        const radiusSearchSize = this.props.searchInputStates.searchQuery.isAdminLevel4Present ? 5000 : 7000
        const circle = new google.maps.Circle({
            strokeColor: '#334FFF',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#33D7FF',
            fillOpacity: 0.35,
            map: this.state.map,
            center: {lat: this.props.searchInputStates.searchQuery.lat, lng: this.props.searchInputStates.searchQuery.lng},
            radius: radiusSearchSize // in meters
        });
        const {lat, lng} = this.props.searchInputStates.searchQuery;
        circle.setCenter({ lat, lng });
        this.state.map.setCenter({ lat, lng });
        this.setState({circle: circle})
    }

    removeAllMarkers() {
        this.state.markers.forEach(marker => {
            marker.setMap(null);
        })
    }

    goToPropertyPage = (uri) => {
        this.props.history.push(uri)
    }

    addMarker() {
        let index = 0;
        this.props.resultStates.propertyGeometries.forEach(location => {
            let marker = new google.maps.Marker({
                position: {lat: location.coordinates[0], lng: location.coordinates[1]},
                map: this.state.map,
                uri: "/property/" + this.props.resultStates.propertyResultData[index].propertyInfoId
            });
            google.maps.event.addListener(marker, 'click', this.goToPropertyPage.bind(marker, marker.uri));
            index++
            this.state.markers.push(marker)
        });
    }

    render() {
        return (
            <div id="map" style={{ height: "84vh" }}></div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        urlState: ownProps.match,
        searchInputStates: state.searchInputStates,
        resultStates: state.resultStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(MapScreen));
