import { LISTING_TYPE } from '../util/Enum/EnumConstants.js';

let defaultState = {
    listingType: LISTING_TYPE.RENT
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case 'LISTING_SELECTOR_CHANGE':
            return { ...state, listingType: action.payload.listingType }
        case 'LISTING_SELECTOR_RESET':
            return { ...state, listingType: LISTING_TYPE.RENT }
        default:
            return state;
    }
}
