var defaultState = {
    myListing: []
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case 'FETCH_MY_LISTING':
            return { ...state, myListing: action.payload };
        case 'LOGOUT':
            return defaultState;
        default:
            return state;
    }
}
