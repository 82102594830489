import React, { Component } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { getPropertyTypeStr } from '../../util/StringTypeConverter.js';

import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';

class BasicPropertyInfoCmp extends Component {
    getShortAddress(propertyData) {
        const formattedAddress = propertyData.formattedAddress;
        if (!formattedAddress) {
            return '';
        }

        const address = formattedAddress.split(',').slice(0, 3).join(', ');
        const unitNumber = propertyData.unitNumber || '';
        const floorNumber = + propertyData.floor || '';

        if (unitNumber || floorNumber) {
            return `${address} (${floorNumber ? 'Floor: ' + floorNumber : ''} ${unitNumber ? ' #' + unitNumber : ''})`;
        }
        return address;

    }

    render() {
        return (
            <Grid container alignItems="flex-start" direction="column">
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {this.getShortAddress(this.props.data)}
                    </Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item style={{marginRight: 8}}>
                        <LocalOfferOutlinedIcon />
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Rp. {this.props.data.price}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item container xs={3}>
                        <Grid item style={{ marginRight: 8 }}>
                            <HotelOutlinedIcon />
                        </Grid>
                        <Grid item>
                            {this.props.data.bedroom}
                        </Grid>
                    </Grid>
                    <Grid item container xs={3}>
                        <Grid item style={{marginRight: 8}}>
                            <BathtubOutlinedIcon />
                        </Grid>
                        <Grid item>
                            {this.props.data.bathroom}
                        </Grid>
                    </Grid>
                    <Grid item container xs={3}>
                        <Grid item style={{marginRight: 8}}>
                            <AspectRatioOutlinedIcon />
                        </Grid>
                        <Grid item>
                            {this.props.data.spaceSizeM2} m<sup>2</sup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        Type: {getPropertyTypeStr(this.props.data.propertyType)}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export default BasicPropertyInfoCmp;
