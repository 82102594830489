import React, {Component} from 'react';

import { Grid, TextField, Typography, Button } from '@material-ui/core';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPassword } from '../../actions/ResetPassword/index.js';

class ForgotPasswordFormCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.resetPassword(this.state.email);
    }

    render() {
        return (
            <div>
                <Grid container item justify="center">
                    <TextField
                        id="outlined-email-input"
                        label="Email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange.bind(this)}
                    />
                </Grid>
                <Button fullWidth variant="contained" color="primary" size="large" onClick={this.handleSubmit.bind(this)}>
                    Reset Password
                </Button>
                {this.props.errorMessage &&
                    <Grid container item>
                        <Typography variant="overline" color="error">{this.props.errorMessage}</Typography>
                    </Grid>
                }
            </div>
        );
    }
};

function mapStateToProps(state, ownProps) {
    return {
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        resetPassword
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ForgotPasswordFormCmp);
