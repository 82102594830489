import React, { Component } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import { addFavorite, removeFavorite  } from '../../actions/FavoriteCmp/index.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import AuthSrvc from '../../service/AuthSrvc.js';

class FavoriteCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: !!this.props.favoriteStates.favoriteProperties[props.propertyInfoId],
            propertyInfoId: props.propertyInfoId || '',
        }
    }

    componentDidUpdate(prevProps) {
        const currentStateChecked = this.props.favoriteStates.favoriteProperties[this.props.propertyInfoId]
        if (prevProps.propertyInfoId !== this.props.propertyInfoId) {
            this.setState({ checked: currentStateChecked });
        }
    }

    handleFavoriteCheck = (isLoggedIn, event) => {
        if (!isLoggedIn) {
            this.props.history.push('/auth');
            return;
        }

        if (event.target.checked) {
            this.props.addFavorite(this.props.propertyData);
            this.setState({checked: true});
        } else {
            this.props.removeFavorite(this.props.propertyData.propertyInfoId);
            this.setState({ checked: false });
        }
    }

    render() {
        const label = AuthSrvc.isLoggedIn ? 'Add to Favorites': 'Please sign in first!';
        const isChecked = AuthSrvc.isLoggedIn ? this.state.checked : false;
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                            onChange={this.handleFavoriteCheck.bind(this, AuthSrvc.isLoggedIn)} />
                    }
                    label={label}
                >
                </FormControlLabel>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        favoriteStates: state.favoriteStates,
        authStates: state.authStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        addFavorite,
        removeFavorite
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(FavoriteCmp));
