export const navList = [
    {
        text: 'HOME',
        url: '/',
        type: 'STATIC'
    },
    {
        text: 'SEARCH',
        url: '/result',
        type: 'STATIC'
    },
    {
        text: 'LOGIN',
        url: '/auth',
        type: 'NOT_AUTHED'
    },
    {
        text: 'DASHBOARD',
        sub: [
            {
                text: 'FAVORITES',
                url: '/dash/favorites',

            },
            {
                text: 'LIST_PROPERTY',
                url: '/dash/create',

            },
            {
                text: 'MY_LISTINGS',
                url: '/dash/listing',

            },
            {
                text: 'MESSAGES',
                url: '/dash/messages',

            },
            {
                text: 'PROFILE',
                url: '/dash/profile',

            },
        ],
        url: '/dash',
        type: 'AUTHED'
    },
];
