let defaultState = {
    searchQuery: {
        name: '', // location name from autocomplete which is selected
        isAdminLevel4Present: false
    },
    isGoogleMapReady: false
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case 'START_SEARCH':
            return {...state, searchQuery: action.payload};
        case 'GMAP_READY':
            return {...state, isGoogleMapReady: true}
        default:
            return state;
    }
}
