import LocalStorageSrvc from './LocalStorageSrvc';
const url = 'https://krumaku-corona.herokuapp.com/graphql';
const ApiSrvc = {};

ApiSrvc.makeQuery = (query, variables = '') => {
    const jwtToken = LocalStorageSrvc.getJwtToken();
    const headers = {};
    headers['Content-Type'] = 'application/json';
    if (jwtToken) {
        headers['Authorization'] = `jwt ${jwtToken}`;
    }
    return fetch(
        url,
        {
            method: 'POST',
            headers,
            mode: 'cors',
            body: JSON.stringify({
                query, variables
            })
        }
    )
    .then(e => e.json());
}

ApiSrvc.makeMultipartQuery = (query, variableName, singleFile) => {
    const jwtToken = LocalStorageSrvc.getJwtToken();
    const headers = {};
    if (jwtToken) {
        headers['Authorization'] = `jwt ${jwtToken}`;
    }

    const body = new FormData();

    const variableMap = {}
    variableMap[variableName] = null
    body.append("operations", JSON.stringify({ "query": query, "variables": variableMap }));
    body.append("map", JSON.stringify({ "0": [`variables.${variableName}`] }));
    body.append(0, singleFile);

    return fetch(
        url,
        {
            method: 'POST',
            headers,
            mode: 'cors',
            body: body
        }
    )
    .then(e => e.json());
}

export default ApiSrvc;
