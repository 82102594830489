import React, { Component } from 'react';
import { Grid, Typography, Link } from '@material-ui/core';

import { Link as LinkRouterDom } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class FooterCmp extends Component {
    changeLanguage() {
        // only support en, id at the moment
        const currentLocale = this.props.i18n.language;
        this.props.i18n.changeLanguage(currentLocale === 'en' ? 'id' : 'en');
    }

    render() {
        return (
            <Grid container direction="column">
                <Grid item container justify="center">
                    <Grid item style={{ padding: '0 4px' }}>
                        <Typography variant="body2" display="block" gutterBottom>
                            <LinkRouterDom to="/contactus">{this.props.t('HOME.CONTACT_US')}</LinkRouterDom>
                        </Typography>
                    </Grid>
                    <Grid item style={{ padding: '0 4px' }}>
                        <Typography variant="body2" display="block" gutterBottom style={{cursor: 'pointer'}}>
                            <Link onClick={this.changeLanguage.bind(this)}>{this.props.t('HOME.CHANGE_LANGUAGE')}</Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container justify="center">
                    <Grid item>
                        <Typography variant="caption" display="block">
                            Made by <span role="img" aria-label="heart">❤️</span>in Cupertino
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation()(FooterCmp);
