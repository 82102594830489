import ApiSrvc from '../../service/ApiSrvc';
import { LISTING_STATUS } from '../../util/Enum/EnumConstants.js';
import EnumManagerInstance from '../../util/Enum/EnumManager.js';

function getQuery(propertyAddress, listingDetails, listingStatus) {
    let propertyInfoInput = '';
    if (propertyAddress.street_number) {
        propertyInfoInput += `streetNumber: "${propertyAddress.street_number}",\n`
    }
    if (propertyAddress.route) {
        propertyInfoInput += `route: "${propertyAddress.route}",\n`
    }
    if (propertyAddress.administrative_area_level_4) {
        propertyInfoInput += `administrativeAreaLevel4: "${propertyAddress.administrative_area_level_4}",\n`
    }
    if (propertyAddress.administrative_area_level_3) {
        propertyInfoInput += `administrativeAreaLevel3: "${propertyAddress.administrative_area_level_3}",\n`
    }
    if (propertyAddress.administrative_area_level_2) {
        propertyInfoInput += `administrativeAreaLevel2: "${propertyAddress.administrative_area_level_2}",\n`
    }
    if (propertyAddress.administrative_area_level_1) {
        propertyInfoInput += `administrativeAreaLevel1: "${propertyAddress.administrative_area_level_1}",\n`
    }
    if (propertyAddress.country) {
        propertyInfoInput += `country: "${propertyAddress.country}",\n`
    }
    if (propertyAddress.postal_code) {
        propertyInfoInput += `postalCode: "${propertyAddress.postal_code}",\n`
    }
    if (propertyAddress.formatted_address) {
        propertyInfoInput += `formattedAddress: "${propertyAddress.formatted_address}",\n`
    }
    if (propertyAddress.lat && propertyAddress.lng) {
        propertyInfoInput += `location: "POINT(${propertyAddress.lat} ${propertyAddress.lng})",\n`
    }
    if (listingDetails.unitNumber) {
        propertyInfoInput += `unitNumber: "${listingDetails.unitNumber}",\n`
    }
    if (listingDetails.price) {
        propertyInfoInput += `minPrice: ${listingDetails.price},\n`
        propertyInfoInput += `maxPrice: ${listingDetails.price},\n`
    }
    if (listingDetails.bedroom) {
        propertyInfoInput += `minBedroom: ${listingDetails.bedroom},\n`
        propertyInfoInput += `maxBedroom: ${listingDetails.bedroom},\n`
    }
    if (listingDetails.bathroom) {
        propertyInfoInput += `minBathroom: ${listingDetails.bathroom},\n`
        propertyInfoInput += `maxBathroom: ${listingDetails.bathroom},\n`
    }
    if (listingDetails.spaceSizeM2) {
        propertyInfoInput += `minSpaceSizeM2: ${listingDetails.spaceSizeM2},\n`
        propertyInfoInput += `maxSpaceSizeM2: ${listingDetails.spaceSizeM2},\n`
    }
    if (listingDetails.floor) {
        propertyInfoInput += `floor: ${listingDetails.floor},\n`
    }
    propertyInfoInput += `propertyType: ${listingDetails.propertyType ? listingDetails.propertyType : 0},\n`
    propertyInfoInput += `listingType: ${listingDetails.listingType ? listingDetails.listingType : 0},\n`
    propertyInfoInput += `listingStatus: ${listingStatus},\n`

    if (listingDetails.description) {
        propertyInfoInput += `description: "${listingDetails.description}",\n`
    }
    if (listingDetails.minLeaseDurationMonth) {
        propertyInfoInput += `minLeaseDurationMonth: ${listingDetails.minLeaseDurationMonth},\n`
    }
    if (listingDetails.securityDeposit) {
        propertyInfoInput += `securityDeposit: ${listingDetails.securityDeposit},\n`
    }
    if (listingDetails.leaseTerms) {
        propertyInfoInput += `leaseTerms: "${listingDetails.leaseTerms}",\n`
    }

    // amenities
    const amenities =
        `
        isFurnished: ${listingDetails.amenities.isFurnished || false},
        isPetAllowed: ${listingDetails.amenities.isPetAllowed || false},
        hasInUnitWasher: ${listingDetails.amenities.hasInUnitWasher || false},
        hasSharedWasher: ${listingDetails.amenities.hasSharedWasher || false},
        hasAc: ${listingDetails.amenities.hasAc || false},
        hasInternet: ${listingDetails.amenities.hasInternet || false},
        hasHotWater: ${listingDetails.amenities.hasHotWater || false},
        hasPrivateParking: ${listingDetails.amenities.hasPrivateParking || false},
        hasPublicParking: ${listingDetails.amenities.hasPublicParking || false},
        hasLoungeArea: ${listingDetails.amenities.hasLoungeArea || false},
        hasGym: ${listingDetails.amenities.hasGym || false},
        hasPlayground: ${listingDetails.amenities.hasPlayground || false},
        hasGarden: ${listingDetails.amenities.hasGarden || false},
        hasPool: ${listingDetails.amenities.hasPool || false},
        hasSecurity: ${listingDetails.amenities.hasSecurity || false},
        hasMaidRoom: ${listingDetails.amenities.hasMaidRoom || false},
        hasKitchen: ${listingDetails.amenities.hasKitchen || false},
        `
    propertyInfoInput += amenities
    const query =
    `mutation createOrUpdatePropertyInfo {
        createOrUpdatePropertyInfo(
            ${listingDetails.propertyInfoId ? ('propertyInfoId:"' + listingDetails.propertyInfoId + '",'): ''}
            propertyInfoInput: {
                ${propertyInfoInput}
            }
        )
        {
            ok
            propertyInfo {
                properties {
                    propertyInfoId
                    streetNumber
                    route
                    administrativeAreaLevel4
                    administrativeAreaLevel3
                    administrativeAreaLevel2
                    administrativeAreaLevel1
                    country
                    postalCode
                    formattedAddress
                    unitNumber
                    price
                    bedroom
                    bathroom
                    spaceSizeM2
                    floor
                    propertyType
                    listingType
                    listingStatus
                    minLeaseDurationMonth
                    securityDeposit
                    leaseTerms
                    description
                    amenities
                }
            }
        }
    }
    `;
    return query;
}

const prefillQuery = (propId) => `{
        myPropertyInfos(
            propertyInfoIds: "${propId}"
            ) {
            edges{
                node {
                    properties {
                        propertyInfoId,
                        price,
                        bedroom,
                        bathroom,
                        propertyType,
                        listingType,
                        spaceSizeM2,
                        floor,
                        formattedAddress,
                        description,
                        listingStatus,
                        streetNumber,
                        route,
                        administrativeAreaLevel4
                        administrativeAreaLevel3,
                        administrativeAreaLevel2,
                        administrativeAreaLevel1,
                        country,
                        postalCode,
                        unitNumber,
                        propertypictureSet {
                            propertyPictureId
                            thumbnail
                            description
                        },
                        minLeaseDurationMonth,
                        securityDeposit,
                        leaseTerms,
                        description,
                        amenities
                    }
                }
            }
        }
    }`;


const uploadPictureQuery = (propertyInfoId, variableName) =>
    `mutation uploadPropertyPicture(
        $${variableName}: Upload!)
            {
                uploadPropertyPicture(
                    propertyInfoId: "${propertyInfoId}",
                    ${variableName}: $${variableName}) {
                        ok,
                        propertyPicture {
                            propertyPictureId,
                            original,
                            thumbnail,
                            description
                        }
                    }
            }`;

const deletePictureQuery = (propertyPictureId) =>
    `mutation deletePropertyPicture {
        deletePropertyPicture(
            propertyPictureId: "${propertyPictureId}"
        ) {
            ok
        }
    }`;

/**
 * update picture data
 * @param {Array} propertyPictureArray [{propertyPictureId: '...', newPosition: 3, description: 'new description'}, ...]
 */
const updatePictureQuery = (propertyPictureArray = []) => {
    const quotesRemoved = JSON.stringify(propertyPictureArray).replace(/"([^(")"]+)":/g, "$1:");
    return `mutation editPropertyPicture {
        editPropertyPicture(
            editPropertyPictureInput: ${quotesRemoved}
        ) {
            ok
        }
    }`;
}

export function propertySaved(response) {
    const propertyInfo = response.data.createOrUpdatePropertyInfo.propertyInfo.properties;
    return {
        type: 'PROPERTY_SAVED',
        payload: propertyInfo
    };
}

export function propertyPictureUpdated() {
    return {
        type: 'PROPERTY_PICTURE_UPDATED'
    }
}

export function propertyPictureUploaded(response, toEditProperty) {
    if (!toEditProperty.propertyPictures) {
        toEditProperty.propertyPictures = []
    }
    toEditProperty.propertyPictures = toEditProperty.propertyPictures.concat([response.data.uploadPropertyPicture.propertyPicture]);
    return {
        type: 'PROPERTY_PICTURE_UPLOADED',
        payload: toEditProperty
    };
}

export function propertyPictureDeleted(toEditProperty, propertyPictureId) {
    toEditProperty.propertyPictures = toEditProperty.propertyPictures.filter( picture => picture.propertyPictureId !== propertyPictureId );
    return {
        type: 'PROPERTY_PICTURE_DELETED',
        payload: toEditProperty
    };
}

export function resetState() {
    return { type: 'RESET_STATE' }
}

export function uploadingPicture() {
    return { type: 'UPLOADING_PICTURE' };
}

export function propertyPrefilled(response) {
    const propertyInfo = response.data.myPropertyInfos.edges[0].node.properties;
    propertyInfo.propertyPictures = propertyInfo.propertypictureSet
    return {
        type: 'PROPERTY_PREFILLED',
        payload: propertyInfo
    };
}

export function savingProperty() {
    return { type: 'SAVING_PROPERTY' };
}

export function prefillingProperty() {
    return { type: 'PREFILLING_PROPERTY' };
}

export function saveProperty(propertyAddress, listingDetails) {
    return function (dispatch, getState) {
        dispatch(savingProperty());
        const query = getQuery(propertyAddress, listingDetails, EnumManagerInstance.getListingStatusEnumMap()[LISTING_STATUS.ACTIVE]);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('property saved', response);
                } else {
                    alert("There is an error while submitting the request")
                }
                dispatch(propertySaved(response));
            })
            .catch((error) => {
                console.error(error, "[saveProperty] error saving property");
            });
    }
}

export function saveAsDraftProperty(propertyAddress, listingDetails) {
    return function (dispatch, getState) {
        dispatch(savingProperty());
        const query = getQuery(propertyAddress, listingDetails, EnumManagerInstance.getListingStatusEnumMap()[LISTING_STATUS.INACTIVE]);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('property saved as draft', response);
                }
                dispatch(propertySaved(response));
            })
            .catch((error) => {
                console.error(error, "[saveAsDraftProperty] error saving property");
            });
    }
}

export function savePropertyPicture(toEditProperty, propertyPictureInput) {
    return function (dispatch) {
        dispatch(uploadingPicture());
        const imageVariable = "propertyPictureInput";
        const query = uploadPictureQuery(toEditProperty.propertyInfoId, imageVariable);
        return ApiSrvc.makeMultipartQuery(query, imageVariable, propertyPictureInput)
            .then(function (response) {
                if (!response.errors) {
                    console.log('Property Picture Uploaded', response);
                    dispatch(propertyPictureUploaded(response, toEditProperty));
                }
            })
            .catch((error) => {
                console.error(error, "[savePropertyPicture] error saving picture");
            });
    }
}

export function deletePropertyPicture(toEditProperty, propertyPictureId) {
    return function (dispatch) {
        dispatch(savingProperty());
        const query = deletePictureQuery(propertyPictureId);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('Property Picture Deleted', response);
                    dispatch(propertyPictureUpdated());
                }
                dispatch(propertyPictureDeleted(toEditProperty, propertyPictureId));
            })
            .catch((error) => {
                console.error(error, "[deletePropertyPicture] error deleting picture");
            });
    }
}

export function updatePropertyPicture(propertyPictureArray) {
    return (dispatch) => {
        dispatch(savingProperty());
        const query = updatePictureQuery(propertyPictureArray);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('picture data updated', response);
                    dispatch(propertyPictureUpdated());
                }
            })
            .catch((error) => {
                console.error(error, "[updatePropertyPicture] error updating picture");
            });
    };
}

export function prefillEditProperty(propertyId) {
    return function (dispatch, getState) {
        const query = prefillQuery(propertyId);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('fetched edit property', response);
                }
                dispatch(propertyPrefilled(response));
            })
            .catch((error) => {
                console.error(error, "[editProperty] error fetching propertyto edit");
            });
    }
}
