var defaultState = {
    isInfoRequested: {}, // propertyInfoId: [true,false]
    propertyData: {},
    similarPropertyInfos: []
};

export default function (state = defaultState, action) {
    const propertyInfoId = (action && action.payload && action.payload.propertyInfoId) || '';
    switch (action.type) {
        case 'GET_PROPERTY_RELATED_INFO':
            if (!propertyInfoId) {
                return state;
            }
            const updatedRequestedInfo = action.payload.isInfoRequested || state.isInfoRequested[propertyInfoId];
            const newIsInfoRequested = { ...state.isInfoRequested, [propertyInfoId]: updatedRequestedInfo};
            return {...state, isInfoRequested: newIsInfoRequested};
        case 'PROPERTY_INFO_REQUEST_SENT':
            if (!propertyInfoId) {
                return state;
            }
            return {...state, isInfoRequested: {...state.isInfoRequested, [propertyInfoId]: true}};
        case 'PREFILL_PROPERTY_DATA':
            return { ...state, propertyData: action.payload }
        case 'POST_FETCH_PROPERTY_BY_ID':
            return { ...state, propertyData: action.payload };
        case 'FETCH_SIMILAR_PROPERTY_INFOS_DATA':
            return { ...state, similarPropertyInfos: action.payload };
        case 'LOGOUT':
            return { ...state };
        case 'VERIFIED_LOGIN':
        default:
            return state;
    }
}
