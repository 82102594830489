import React, {Component} from 'react';
import { Grid, Button , TextField, Typography } from '@material-ui/core';
import { FormValidator, FieldRuleValidation } from '../../util/Validator/FormValidator.js';
import { validateIndonesianPhoneNumber } from '../../util/Validator/FieldValidator.js';
import { editUserProfile } from '../../actions/AuthScreen/index.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAlert } from '../../actions/Common/index.js';
import PropTypes from 'prop-types';

class EditUserProfileFormCmp extends Component {
    validator = undefined;
    validationResult = undefined;

    constructor(props) {
        super(props);
        this.validator =
            new FormValidator(
                [
                    new FieldRuleValidation('firstName', (value) => value, false),
                    new FieldRuleValidation('lastName', (value) => value, false),
                    new FieldRuleValidation('phoneNumber', validateIndonesianPhoneNumber, false),
                ]
            )
        this.state = {
            classes: PropTypes.object.isRequired,
            firstName: props.authStates.authInfo.firstName,
            lastName: props.authStates.authInfo.lastName,
            phoneNumber: props.authStates.authInfo.phoneNumber,
            email: props.authStates.authInfo.userEmail
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        const newStateToValidate = { ...this.state, [event.target.name]: event.target.value };
        this.validationResult = this.validator.validate(newStateToValidate);
    }

    handleSaveUserProfile(event) {
        event.preventDefault();
        if (!this.validationResult) {
            this.validationResult = this.validator.validate(this.state)
        }
        if (this.validationResult.isCompleteValid) {
            this.props.editUserProfile(this.state);
            this.props.setAlert("User profile saved")
        } else {
            this.props.setAlert("Please fill all fields properly")
        }
    }

    componentDidUpdate(prevProps) {
        // email should always be present!
        if (prevProps.authStates.authInfo.userEmail !== this.props.authStates.authInfo.userEmail) {
            this.setState({
                firstName: this.props.authStates.authInfo.firstName,
                lastName: this.props.authStates.authInfo.lastName,
                phoneNumber: this.props.authStates.authInfo.phoneNumber,
                email: this.props.authStates.authInfo.userEmail
            })
        }
    }

    render() {
        return (
            <Grid item container direction="column" alignItems="center">
                <Grid item container justify="center">
                    <Grid item>
                        <Typography variant="h6">My Profile</Typography>
                    </Grid>
                </Grid>
                <Grid item container justify="center">
                    <Grid item container direction="column" xs={12} sm={6} md={4}>
                        <form onSubmit={this.handleSubmit}>
                            <Grid container item justify="center">
                                <TextField
                                    error={this.validationResult && !this.validationResult.result['firstName'].isValid}
                                    id="firstName"
                                    name="firstName"
                                    autoComplete="firstName"
                                    label="First Name"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.firstName}
                                    fullWidth
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Grid>
                            <Grid container item justify="center">
                                <TextField
                                    error={this.validationResult && !this.validationResult.result['lastName'].isValid}
                                    id="lastName"
                                    name="lastName"
                                    autoComplete="lastName"
                                    label="Last Name"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.lastName}
                                    fullWidth
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Grid>
                            <Grid container item justify="center">
                                <TextField
                                    error={this.validationResult && !this.validationResult.result['phoneNumber'].isValid}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    autoComplete="phoneNumber"
                                    label="Phone"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.phoneNumber}
                                    fullWidth
                                    onChange={this.handleChange.bind(this)}
                                    helperText="(ex: +6281xxxxxxxx)"
                                />
                            </Grid>
                            <Grid container item justify="center">
                                <TextField
                                    id="outlined-email-input"
                                    label="Email"
                                    type="email"
                                    name="email"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.email}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Button fullWidth variant="contained" color="primary" size="large" onClick={this.handleSaveUserProfile.bind(this)}>
                                Save
                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    }

function mapStateToProps(state, ownProps) {
    return {
        authStates: state.authStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        editUserProfile,
        setAlert
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(EditUserProfileFormCmp);
