export const LISTING_TYPE = {
    // BUY: 'BUY',
    RENT: 'RENT'
}

export const PROPERTY_TYPE = {
    HOUSE: 'HOUSE',
    APARTMENT: 'APARTMENT',
    ROOM: 'ROOM'
}

export const LISTING_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    SOLD: 'SOLD'
}
