import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getListingTypeStr, getPropertyTypeStr } from '../../util/StringTypeConverter.js';
import Logo from '../../assets/logo/logo_transparent.png';
import FavoriteCmp from '../FavoriteCmp/FavoriteCmp.js';
import { withRouter } from 'react-router';

class SimilarPropertyThumbCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired
        }
    }

    selectProperty = () => {
        const propertyData = this.props.data;
        this.props.history.push(`/property/${propertyData.propertyInfoId}`, {propertyData});
    }

    render() {
        let mainImage;
        if (this.props.data && this.props.data.propertypictureSet && this.props.data.propertypictureSet[0] && this.props.data.propertypictureSet[0].thumbnail) {
            mainImage = this.props.data.propertypictureSet[0].thumbnail
        } else {
            mainImage = Logo;
        }
        return (
            <Grid container alignItems="flex-start" direction="column" style={{ padding: 30 }}>
                <img src={mainImage} alt="main_image" style={{ display: "block", marginLeft: "auto", marginRight: "auto", height:250, cursor: "pointer" }} onClick={this.selectProperty.bind(this)}></img>
                <Grid container item xs={12} alignItems="flex-end">
                    <FavoriteCmp
                        propertyInfoId={this.props.data.propertyInfoId}
                        propertyData={this.props.data} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    {this.props.data.price}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    {getListingTypeStr(this.props.data.listingType)}, {getPropertyTypeStr(this.props.data.propertyType)}, {this.props.data.bedroom} Bed(s) {this.props.data.bathroom} Bath(s)
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    {this.props.data.formattedAddress}
                </Grid>
            </Grid>
        )
    }
}

export default withRouter(SimilarPropertyThumbCmp);
