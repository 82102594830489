import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeHomeScreenState } from '../../actions/HomeScreen/index.js';
import FilterSelectorCmp from '../FilterSelectorCmp/FilterSelectorCmp.js';
import PropertyListCmp from '../PropertyListCmp/PropertyListCmp.js';
import SearchInputCmp from '../SearchInputCmp/SearchInputCmp.js';
import AdditionalFiltersCmp from '../AdditionalFiltersCmp/AdditionalFiltersCmp.js';
import MapCmp from '../MapCmp/MapCmp.js';
import AuthSrvc from '../../service/AuthSrvc';
import {
    fetchProperty,
    searchProperty,
    selectPriceMax,
    selectPriceMin,
    selectPropertyType,
    selectBedNumber,
    selectBathNumber,
    selectAmenities
} from '../../actions/ResultScreen/index.js';
import { changePropertySelectorCmpState } from '../../actions/PropertySelectorCmp/index.js';

// material-ui
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Autorenew';
import withWidth from '@material-ui/core/withWidth';

import { PROPERTY_TYPE, LISTING_TYPE } from '../../util/Enum/EnumConstants.js';
import EnumManagerInstance from '../../util/Enum/EnumManager.js';
import { startSearch } from '../../actions/SearchInputCmp/index.js';
import { getProfile } from '../../actions/AuthScreen/index.js';

import { withRouter } from 'react-router';

import { setAlert } from '../../actions/Common/index.js';

class ResultScreen extends Component {
    componentDidUpdate(prevProps) {
        // TODO: better check so as not to trigger from /result to result/:id (and vice versa)
        if (prevProps.location !== this.props.location) {
            // TODO: handle URL update on browser back button
            console.log('TODO: handle URL update on browser back button')

            // when user starts at property page, and go back to list page,
            // make a search based on last selected administrative_area_level_3 + 'BUY' type
            const currentArea = this.props.resultStates.propertyResultData[0] && this.props.resultStates.propertyResultData[0].administrativeAreaLevel3;
            if (currentArea) {
                this.props.startSearch({ administrative_area_level_3: currentArea});
                this.props.searchProperty();
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            priceSelector: [],
            numberOfBedsEnum: ["0+", "1+", "2+", "3+", "4+", "5+", "6+"],
            numberOfBathsEnum: ["0+", "1+", "2+", "3+", "4+"],
        }

        // pre-populate data (page refresh on result screen)
        if (AuthSrvc.isLoggedIn) {
            this.props.getProfile();
        }

        // prefill price selector
        for (let i = 0; i <= 50000; i += 5000) {
            this.state.priceSelector.push(i);
        }

        // found propertyId in URL
        if (this.props.history.action === "POP" && this.props.match.params.id) {
            const toSearchByPropertyId = {
                propertyInfoId: this.props.match.params.id,
            };
            // prefill searchQuery
            this.props.startSearch(toSearchByPropertyId);

            this.props.searchProperty();
            return;
        }

        // // [START] DEV ONLY
        // // prefill searchQuery with mock data
        // const toSearch = {
        //     administrative_area_level_3: "Kebayoran Lama",
        // };
        // // prefill searchQuery
        // this.props.startSearch(toSearch);
        // // [END] DEV ONLY
    }

    handlePriceMinChange = (newVal) => {
        const minPrice = parseInt(newVal)
        const maxPrice = parseInt(this.props.resultStates.priceMax)
        if (minPrice !== -1 && maxPrice !== -1 && minPrice > maxPrice) {
            this.props.setAlert('Make sure minimum price is smaller than maximum price!');
            return true;
        }
        this.props.selectPriceMin(minPrice);
        this.refresh();
    }

    handlePriceMaxChange = (newVal) => {
        const minPrice = parseInt(this.props.resultStates.priceMin)
        const maxPrice = parseInt(newVal)
        if (maxPrice !== -1 && minPrice !== -1 && maxPrice < minPrice) {
            this.props.setAlert('Make sure maximum price is greather than minimum price!');
            return true;
        }
        this.props.selectPriceMax(maxPrice);
        this.refresh();
    }

    handlePropertyTypeChange = (newVal) => {
        this.props.selectPropertyType(EnumManagerInstance.getPropertyTypeEnumMap()[newVal]);
        this.refresh();
    }

    convertStringWithPlusToInt = (value) => {
        return parseInt(value.replace('+', ''));
    }

    handleBedNumberChange = (newVal) => {
        this.props.selectBedNumber(this.convertStringWithPlusToInt(newVal));
        this.refresh();
    }

    handleBathNumberChange = (newVal) => {
        this.props.selectBathNumber(this.convertStringWithPlusToInt(newVal));
        this.refresh();
    }

    handleListingTypeChange = (newVal) => {
        this.props.changePropertySelectorCmpState({propertyType: newVal});
        this.refresh();
    }

    handleAmenitiesChange = (newVal = '') => {
        this.props.selectAmenities(newVal);
        // fetch manually after selecting amenities
        this.refresh();
    }

    refresh = () => {
        this.props.fetchProperty();
    }

    isWideScreen() {
        return this.props.width === 'md' || this.props.width === 'lg';
    }

    render() {
        const gMapStickyStyle = this.isWideScreen() ? {paddingLeft: 15, paddingRight: 15, height: '100vh', position: 'sticky', top: '4rem'} : {paddingLeft: 15, paddingRight: 15}
        return (
            <div style={{flexGrow: 1}}>
                <Grid container direction="column" style={{marginTop: 80}}>
                    {/* Filter */}
                    <Grid container item justify="center">
                        <Grid item xs={6} md={4}>
                            {
                                this.props.searchInputStates.isGoogleMapReady ? <SearchInputCmp locationName={this.props.searchInputStates.searchQuery.name}/> : 'Loading...'
                            }
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <FilterSelectorCmp
                                filterName="Listing Type"
                                onChange={this.handleListingTypeChange}
                                choices={LISTING_TYPE} // Only rent for now
                                currentVal={this.props.listingTypeSelectorStates.listingType} />
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <FilterSelectorCmp
                                filterName="Property Type"
                                onChange={this.handlePropertyTypeChange}
                                choices={PROPERTY_TYPE} />
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <FilterSelectorCmp
                            filterName="Minimum Price"
                            onChange={this.handlePriceMinChange}
                            choices={this.state.priceSelector} />
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <FilterSelectorCmp
                            filterName="Maximum Price"
                            onChange={this.handlePriceMaxChange}
                            choices={this.state.priceSelector} />
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <FilterSelectorCmp
                            filterName="# of Beds"
                            onChange={this.handleBedNumberChange}
                            choices={this.state.numberOfBedsEnum} />
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <FilterSelectorCmp
                                filterName="# of Baths"
                                onChange={this.handleBathNumberChange}
                                choices={this.state.numberOfBathsEnum} />
                        </Grid>

                        {/* Refresh */}
                        <Grid item xs={2} style={{padding: 10}}>
                            <IconButton className={this.state.classes.menuButton} color="inherit" aria-label="refresh" onClick={this.refresh}>
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={12} style={{paddingLeft: 15, paddingRight: 15}}>
                            <AdditionalFiltersCmp
                                handleAmenitiesChange={this.handleAmenitiesChange} />
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={12} md={6} style={gMapStickyStyle}>
                            <MapCmp/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PropertyListCmp data={this.props.resultStates.propertyResultData} />
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        urlState: ownProps.match,
        resultStates: state.resultStates,
        authStates: state.authStates,
        listingTypeSelectorStates: state.listingTypeSelectorStates,
        searchInputStates: state.searchInputStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        changeHomeScreenState,
        fetchProperty,
        searchProperty,
        selectPriceMax,
        selectPriceMin,
        selectPropertyType,
        selectBedNumber,
        selectBathNumber,
        selectAmenities,
        startSearch,
        getProfile,
        changePropertySelectorCmpState,
        setAlert
    }, dispatch);
}

export default withRouter(withWidth()(connect(mapStateToProps, matchDispatchToProps)(ResultScreen)));
