import ApiSrvc from '../../service/ApiSrvc';

function buildContactUsMutation(name, email, message) {
    const query = `
        mutation contactUs {
            contactUs (
                contactUsInput: {
                    name: "${name}"
                    email: "${email}"
                    message: "${message}"
            }) {
                ok
            }
        }
    `;
    return query;
}

export function contactUs(name, email, message) {
    return function () {
        const query = buildContactUsMutation(name, email, message);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    alert("Message submitted")
                }
            })
            .catch((error) => {
                console.error(error, "[contactUs] error saving property");
            });
    }
}
