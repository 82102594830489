import React, {Component} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid, Link, TextField, Typography } from '@material-ui/core';

import AuthBtnGroupsCmp from '../AuthBtnGroupsCmp/AuthBtnGroupsCmp.js';
import { FormValidator, FieldRuleValidation } from '../../util/Validator/FormValidator.js';
import { validateEmail } from '../../util/Validator/FieldValidator.js';

import PropTypes from 'prop-types';

class AuthBasicFormCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            email: undefined,
            password: undefined,
        };
        this.validationResult = undefined;
        this.validator =
            new FormValidator(
                [
                    new FieldRuleValidation('email', validateEmail, true),
                    new FieldRuleValidation('password', (value) => value, true),
                ]
            )
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
        this.setState({ [event.target.name]: event.target.value })
        const newStateToValidate = { ...this.state, [event.target.name]: event.target.value };
        this.validationResult = this.validator.validate(newStateToValidate);
    }

    render() {
        const forgotPasswordLink = React.forwardRef((props, ref) => (
            <RouterLink innerRef={ref} to="/auth/forgotpassword" {...props} />
        ));
        return (
            <div>
                <Grid container item justify="center">
                    <TextField
                        error={ this.validationResult && !this.validationResult.result['email'].isValid }
                        id="outlined-email-input"
                        label="Email"
                        className={this.state.classes.textField}
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                </Grid>
                <Grid container item justify="center">
                    <TextField
                        error={ this.validationResult && !this.validationResult.result['password'].isValid }
                        id="outlined-password-input"
                        label="Password"
                        className={this.state.classes.textField}
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                </Grid>
                {this.props.errorMessage &&
                    <Grid container item>
                        <Typography variant="overline" color="error">{this.props.errorMessage}</Typography>
                    </Grid>
                }
                {
                    this.props.formType === 'signup' &&
                        <Grid container item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.currentState.isAgentCheckbox}
                                        onChange={this.props.handleUserTypeChange}
                                        value="isAgent"
                                    />
                                }
                                label="I am an Agent"
                            />
                        </Grid>
                }
                {
                    this.props.formType === 'login' &&
                        <Grid container item justify="center">
                            <Link component={forgotPasswordLink}>Forgot Password?</Link>
                        </Grid>
                }
                <br/>
                <AuthBtnGroupsCmp isSignUpForm={this.props.formType === 'signup'} currentState={this.props.currentState} canSubmit={this.validationResult && this.validationResult.isCompleteValid}/>
            </div>
        );
    }
};

export default AuthBasicFormCmp;
