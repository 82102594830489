import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router';

// Components
import PropTypes from 'prop-types';
import AuthBasicFormCmp from '../AuthBasicFormCmp/AuthBasicFormCmp.js';

import { switchToSignUp, switchToLogIn, switchToNormalUser, switchToAgent } from '../../actions/AuthScreen/index.js';

// material-ui
import { Grid, Typography } from '@material-ui/core';

class AuthScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            classes: PropTypes.object.isRequired,
            isAgentCheckbox: false,
            email: '',
            password: '',
            agentInfo: {
                firstName: '',
                lastName: ''
            }
        }
    }

    handleUserTypeChange = (event) => {
        const newVal = event.target.checked;
        this.setState(() => ({ isAgentCheckbox: newVal }));
        if (newVal) {
            this.props.switchToAgent();
        } else {
            this.props.switchToNormalUser();
        }
    }

    handleBasicInputChange = (event) => {
        if (event.target.name === 'email') {
            const newEmailVal = event.target.value;
            this.setState({ email: newEmailVal });
        }
        if (event.target.name === 'password') {
            const newPasswordVal = event.target.value;
            this.setState({ password: newPasswordVal });
        }
    }

    handleAgentInputChange = (event) => {
        const newAgentInfo = {...this.state.agentInfo};
        if (event.target.name === 'firstName') {
            newAgentInfo.firstName = event.target.value;
            this.setState({ agentInfo: newAgentInfo });
        }
        if (event.target.name === 'lastName') {
            newAgentInfo.lastName = event.target.value;
            this.setState({ agentInfo: newAgentInfo });
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.authStates.isLoggedIn && this.props.authStates.isLoggedIn) {
            if (prevProps.authStates.userType === 'agent') {
                // this flow is used for agent sign up
                this.props.history.push('/auth/agent');
            } else {
                // after user is logged in, return to the previously visited page
                this.props.history.goBack();
            }
        }
        // TODO: add error handling, on API error should stay in AuthScreen
    }

    render() {
        return (
            <div>
                <Grid container style={{ marginTop: 100 }}>
                    <Grid container item xs={1} md={4}/>
                    <Grid direction="column" container item xs={10} md={4} spacing={3} justify="center">
                        <Grid container item justify="center">
                            <Typography variant="h4">Hi There! <span role="img" aria-label="waving hand">👋</span></Typography>
                        </Grid>
                        <AuthBasicFormCmp
                            formType={this.props.authStates.formType}
                            handleAgentInputChange={this.handleAgentInputChange.bind(this)}
                            handleInputChange={this.handleBasicInputChange.bind(this)}
                            handleUserTypeChange={this.handleUserTypeChange.bind(this)}
                            errorMessage={this.props.authStates.errorMessage}
                            currentState={this.state}
                        />
                    </Grid>
                    <Grid container item xs={1} md={4} />
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authStates: state.authStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        switchToSignUp,
        switchToLogIn,
        switchToNormalUser,
        switchToAgent
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(AuthScreen));
