import React, { Component } from 'react';

import { Grid } from '@material-ui/core';

import Logo from '../../assets/logo/logo_transparent.png';
import FavoriteCmp from '../FavoriteCmp/FavoriteCmp.js';
import BasicPropertyInfoCmp from '../BasicPropertyInfoCmp/BasicPropertyInfoCmp';

class PropertyThumbCmp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldShowFavoriteCmp: false // default to false
        }

        // props need to be false, undefined means default behavior
        this.state.shouldShowFavoriteCmp = props.isLoggedIn;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            this.props.shouldShowFavoriteCmp = this.props.isLoggedIn;
        }
    }

    selectProperty() {
        if (this.props.handleToPropertyInfo) {
            this.props.handleToPropertyInfo(this.props.data);
        }
    }

    getShortAddress(formattedAddress) {
        return formattedAddress.split(',').slice(0, 3).join(', ');
    }

    render() {
        let mainImage;
        if (this.props.data && this.props.data.propertypictureSet && this.props.data.propertypictureSet[0] && this.props.data.propertypictureSet[0].thumbnail) {
            mainImage = this.props.data.propertypictureSet[0].thumbnail
        } else {
            mainImage = Logo;
        }
        return (
            <Grid item xs={12} sm={6} md={6}>
                <Grid container alignItems="flex-start" direction="column" style={{ padding: 20 }}>
                    <Grid item xs={12}>
                        <img src={mainImage} alt="main_image" style={{ height:215, cursor: "pointer" }} onClick={this.selectProperty.bind(this)}></img>
                    </Grid>
                    {
                        this.state.shouldShowFavoriteCmp &&
                        <Grid container item xs={12} alignItems="flex-end">
                            <FavoriteCmp
                                propertyInfoId={this.props.data.propertyInfoId}
                                propertyData={this.props.data} />
                        </Grid>
                    }
                    <BasicPropertyInfoCmp data={this.props.data}></BasicPropertyInfoCmp>
                </Grid>
            </Grid>
        )
    }
}

export default PropertyThumbCmp;
