export const AvailableFilters = [
    {
        name: 'hasKitchen',
        label: 'Kitchen'
    },
    {
        name: 'isPetAllowed',
        label: 'Pets Allowed'
    },
    {
        name: 'hasSharedWasher',
        label: 'Shared Washer'
    },
    {
        name: 'hasInUnitWasher',
        label: 'In Unit Washer'
    },
    {
        name: 'hasAc',
        label: 'A/C'
    },
    {
        name: 'hasInternet',
        label: 'Internet'
    },
    {
        name: 'hasHotWater',
        label: 'Hot Water'
    },
    {
        name: 'isFurnished',
        label: 'Furnished'
    },
    {
        name: 'hasPrivateParking',
        label: 'Private Parking'
    },
    {
        name: 'hasPublicParking',
        label: 'Public Parking'
    },
    {
        name: 'hasLoungeArea',
        label: 'Social Lounge Area'
    },
    {
        name: 'hasGym',
        label: 'Gym',
    },
    {
        name : 'hasPlayground',
        label: 'Playground'
    },
    {
        name: 'hasGarden',
        label: 'Garden'
    },
    {
        name: 'hasPool',
        label: 'Pool'
    },
    {
        name: 'hasSecurity',
        label: 'Security'
    },
    {
        name: 'hasMaidRoom',
        label: 'Maid Room'
    },
]

function getFilterMap() {
    const map = {};
    AvailableFilters.forEach(f => {
        map[f.name] = f.label;
    });
    return map;
}

export const AvailableFiltersMap = getFilterMap();
