// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Router
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

// import components
import HomeScreen from '../HomeScreen/HomeScreen.js';
import ResultScreen from '../ResultScreen/ResultScreen.js';
import AuthScreen from '../AuthScreen/AuthScreen.js';
import Script from 'react-load-script';
import CreatePropertyScreen from '../CreatePropertyScreen/CreatePropertyScreen.js';
import ContactUsScreen from '../ContactUsScreen/ContactUsScreen.js';
import ForgotPasswordScreen from '../ForgotPasswordScreen/ForgotPasswordScreen.js';
import ChangePasswordScreen from '../ChangePasswordScreen/ChangePasswordScreen.js';
import PropertyAnalyticsDashboardScreen from '../PropertyAnalyticsDashboardScreen/PropertyAnalyticsDashboardScreen.js';
import PropertyInfoScreen from '../PropertyInfoScreen/PropertyInfoScreen.js';
import NavBar from '../NavBar/NavBar.js';
import FooterCmp from '../FooterCmp/FooterCmp';
import DashboardScreen from '../DashboardScreen/DashboardScreen.js';
import SnackbarCmp from '../common/SnackbarCmp/SnackbarCmp.js';
import Grid from '@material-ui/core/Grid';

// import service
import AuthSrvc from '../../service/AuthSrvc';

import { gMapIsReady } from '../../actions/SearchInputCmp/index.js';
import AgentSignUpScreen from '../AgentSignUpScreen/AgentSignUpScreen.js';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        AuthSrvc.isLoggedIn
            ? <Component {...props} />
            : <Redirect to='/auth' />
    )} />
)

const AuthScreenRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        AuthSrvc.isLoggedIn
            ? <Redirect to='/dash/profile' />
            : <Component {...props} />
    )} />
)

class MainCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gMapLoaded: false,
            readyToRender: false,
            isCollapsed: false,
        }
    }

    handleScriptLoad = () => {
        console.log('Google Map is loaded');
        this.setState(() => ({ gMapLoaded: true }));
        this.props.gMapIsReady();
    }

    render() {
        return (
            <div>
                <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDIBHW2UmRxqLDWrEQJSGrIjjiQCWSITPY&libraries=places"
                    onLoad={this.handleScriptLoad}
                />
                <Router>
                    {
                        this.props.shouldRender && (
                                <div>
                                    <NavBar />
                                    <SnackbarCmp />
                                    {/* <MainContent /> */}
                                    <Grid style={{ marginTop: 64, minHeight: 'calc(100vh - 130px)', flexDirection: 'column' }}>
                                        <Switch >
                                            <Route path="/" exact component={HomeScreen} />
                                            <AuthScreenRoute path="/auth" exact component={AuthScreen} />
                                            <PrivateRoute path="/auth/agent" exact component={AgentSignUpScreen}></PrivateRoute>
                                            <PrivateRoute path="/edit/:id?" exact component={CreatePropertyScreen} />
                                            <PrivateRoute path="/dash/property_analytics/:id?" exact component={PropertyAnalyticsDashboardScreen}></PrivateRoute>
                                            <PrivateRoute path="/dash/:type?/:id?" exact component={DashboardScreen}></PrivateRoute>
                                            <Route path="/result/:id?" component={ResultScreen} />
                                            <Route path="/property/:id" component={PropertyInfoScreen} />
                                            <Route path="/contactus" exact component={ContactUsScreen} />
                                            <Route path="/auth/forgotpassword" exact component={ForgotPasswordScreen} />
                                            <Route path="/auth/resetpassword/:token?" exact component={ChangePasswordScreen} />
                                            {/* Fallback route */}
                                            <Route render={() => (<Redirect to="/" />)} />
                                        </Switch>
                                    </Grid>
                                    <footer>
                                        <FooterCmp/>
                                    </footer>
                                </div>
                        )
                    }
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        gMapIsReady,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(MainCmp);
