import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetAlert } from '../../../actions/Common/index.js';

import PropTypes from 'prop-types';

class SnackbarCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removeIn: props.removeIn || 2500, // default to 2.5s
            isOpened: false,
            message: '',
            classes: PropTypes.object.isRequired
        }
    }

    componentDidUpdate(prevProps) {
        // ignore everything when its opened
        if (this.state.isOpened) {
            return;
        }
        if (prevProps.commonStates.message !== this.props.commonStates.message) {
            if (this.props.commonStates.message) {
                this.setState({ isOpened: true })
            }
        }
    }

    handleClick() {
        this.setState({isOpened: true})
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ isOpened: false })
        this.props.resetAlert();
        if (this.props.callback) {
            this.props.callback();
        }
    }

    render() {
        if (!this.props.commonStates.message) {
            return null;
        }
        return (
            <div>
                {/* For Dev*/}
                {/* <Button onClick={this.handleClick.bind(this)}>Open simple snackbar</Button> */}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.isOpened}
                    autoHideDuration={this.state.removeIn}
                    onClose={this.handleClose.bind(this)}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.commonStates.message}</span>}
                    // TODO: add props for snackbar type (INFO, SUCCESS, ERROR)
                    // change icon depending on type
                    // action={[
                    //     <IconButton
                    //         key="close"
                    //         aria-label="Close"
                    //         color="inherit"
                    //         className={this.state.classes.close}
                    //     >
                    //         <CheckIcon />
                    //     </IconButton>,
                    // ]}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonStates: state.commonStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        resetAlert
    }, dispatch);
}


export default (connect(mapStateToProps, matchDispatchToProps)(SnackbarCmp));
