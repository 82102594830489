import ApiSrvc from '../../service/ApiSrvc';
import AuthSrvc from '../../service/AuthSrvc';

export function postAddFavorite(propertyData) {
    return {
        type: 'ADD_FAVORITE',
        payload: propertyData
    };
}

export function postRemoveFavorite(propertyInfoId) {
    return {
        type: 'REMOVE_FAVORITE',
        payload: propertyInfoId
    }
}

export function postGetFavorites(response) {
    return {
        type: 'GET_FAVORITES',
        payload: response.map((obj) => obj.node.properties) || []
    }
}

export function addFavorite(propertyData) {
    const propertyInfoId = propertyData.propertyInfoId;
    return (dispatch, getStates) => {
        if (!AuthSrvc.isLoggedIn) {
            console.warn('user is not logged in or jwt token is missing; please re-login');
            return;
        }
        const query = `
            mutation addSavedHome {
                addSavedHome (
                    savedHomeInput: {
                        propertyInfoId: "${propertyInfoId}"
                    }
                ) {
                    ok
                    userProfile {
                        userProfileId
                    }
                }
            }
        `;
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                // OK
                dispatch(postAddFavorite(propertyData));
            })
            .catch((error) => {
                console.error(error, "[Favorite] error adding property to favorite");
            });
    }
}

export function removeFavorite(propertyInfoId) {
    return (dispatch, getStates) => {
        if (!AuthSrvc.isLoggedIn) {
            console.warn('user is not logged in or jwt token is missing; please re-login');
            return;
        }

        const query = `
            mutation addSavedHome {
                removeSavedHome (
                    savedHomeInput: {
                        propertyInfoId: "${propertyInfoId}"
                    }
                ) {
                    ok
                    userProfile {
                        userProfileId
                    }
                }
            }
        `;

        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                // OK
                dispatch(postRemoveFavorite(propertyInfoId));
            })
            .catch((error) => {
                console.error(error, "[Favorite] error removing property from favorite");
            });
    }
}

export function getFavorites() {
    return (dispatch, getStates) => {
        if (!AuthSrvc.isLoggedIn) {
            console.warn('user is not logged in or jwt token is missing; please re-login');
            return;
        }

        const query = `
            query userData {
                userData {
                    __typename
                    ... on UserProfileNode {
                        userProfileId
                        firstName
                        lastName
                        email
                        phoneNumber
                        savedHomes {
                            edges {
                                node {
                                    properties {
                                        propertyInfoId
                                        formattedAddress
                                        propertyType
                                        listingType
                                        bathroom
                                        bedroom
                                        price
                                        userProfile {
                                            firstName
                                            lastName
                                            email
                                            phoneNumber
                                        }
                                        propertypictureSet {
                                            propertyPictureId
                                            thumbnail
                                            original
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `;

        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if(response.errors) {
                    return Promise.reject();
                }
                // OK
                const data = response.data.userData[1].savedHomes.edges;
                dispatch(postGetFavorites(data))
            })
            .catch((error) => {
                console.error(error, "[Favorite] error getting user's favorites");
            });
    }
}

