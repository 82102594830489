// ***********************************
// MY LISTINGS COMPONENT
// ***********************************
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Button, Grid, Typography } from '@material-ui/core';

import PropertyThumbEditCmp from '../PropertyThumbEditCmp/PropertyThumbEditCmp.js';
import FilterSelectorCmp from '../FilterSelectorCmp/FilterSelectorCmp.js';
import { getMyListing, changeListingStatus } from '../../actions/AgentDashboardScreen/index.js';
import { setAlert } from '../../actions/Common/index.js';
import { LISTING_STATUS } from '../../util/Enum/EnumConstants.js';
import EnumManagerInstance from '../../util/Enum/EnumManager.js';

import AuthSrvc from '../../service/AuthSrvc';

class AgentDashboardScreen extends Component {
    noneListingStatusFilterValue = -1

    constructor(props) {
        super(props);
        this.state = {
            activeListingStatusFilter: this.noneListingStatusFilterValue // By default, show all listings without filtering.
        }
        if (!AuthSrvc.isAgent) {
            this.props.setAlert('Please convert your user to an agent first');
            this.props.history.push('/auth/agent');
        }
        this.props.getMyListing();
    }

    changeListingToSold = (propId) => {
        this.props.changeListingStatus(propId, EnumManagerInstance.getListingStatusEnumMap()[LISTING_STATUS.SOLD]);
        this.props.getMyListing(); // to get updated listings
    }

    editListing(propId) {
        this.props.history.push('/edit/' + propId);
    }

    goToListing(propId) {
        this.props.history.push('/property/' + propId);
    }

    handleListingStatusFilterChange = (newVal) => {
        this.setState({activeListingStatusFilter: EnumManagerInstance.getListingStatusEnumMap()[newVal]});
    }

    goToAddProperty = () => {
        this.props.history.push('/dash/create');
    }

    goToPropertyAnalytics = (propId) => {
        this.props.history.push('/dash/property_analytics/' + propId);
    }

    render() {
        return (
            <div>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">My Listing</Typography>
                    </Grid>
                    <Grid item style={{width: 120}}>
                        <FilterSelectorCmp
                            filterName="Listing Status"
                            currentVal={this.state.activeListingStatusFilter}
                            onChange={this.handleListingStatusFilterChange}
                            choices={Object.values(LISTING_STATUS)}
                            extraStyle={{ marginLeft: 10, marginRight: 10 }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 20 }}>
                        <Button variant="contained" size="medium"
                            style={{ backgroundColor: 'lightgreen' }}
                            onClick={this.goToAddProperty.bind(this)}>+ Add a new listing</Button>
                    </Grid>
                    <Grid container item xs={12} md={10}>
                        {/* TODO: create different component for my listing */}
                        {this.props.dashboardStates.myListing.map((properties) =>
                            // filter listings or show everything if filter isn't present
                            (this.state.activeListingStatusFilter === this.noneListingStatusFilterValue || properties.listingStatus === this.state.activeListingStatusFilter) &&
                            <PropertyThumbEditCmp
                                key={properties.propertyInfoId}
                                data={properties}
                                onClickAlreadySoldProp={this.changeListingToSold.bind(this)}
                                onClickEditProp={this.editListing.bind(this)}
                                onClickGoToListing={this.goToListing.bind(this)}
                                onClickPropertyAnalytics={this.goToPropertyAnalytics}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        dashboardStates: state.dashboardStates,
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getMyListing,
        changeListingStatus,
        setAlert
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(AgentDashboardScreen));
