const JWT_TOKEN_KEY = 'krumaku_auth_token';

let jwtTokenCached = '';

const LocalStorageSrvc = {};

LocalStorageSrvc.initJwtToken = () => {
    return LocalStorageSrvc.getJwtToken();
}

LocalStorageSrvc.getJwtToken = () => {
    if (!jwtTokenCached) {
        jwtTokenCached = localStorage.getItem(JWT_TOKEN_KEY)
    }
    return jwtTokenCached;
}

LocalStorageSrvc.setJwtToken = (token) => {
    if (LocalStorageSrvc.getJwtToken()) {
        LocalStorageSrvc.clearJwtToken();
    }
    if (token) {
        localStorage.setItem(JWT_TOKEN_KEY, token);
        jwtTokenCached = token;
    }
    return jwtTokenCached;
}

LocalStorageSrvc.clearJwtToken = () => {
    jwtTokenCached = '';
    localStorage.removeItem(JWT_TOKEN_KEY);
}

export default LocalStorageSrvc;
