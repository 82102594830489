import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locales/en/translation.json';
import id from './locales/id/translation.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {en, id},
        lng: "en", // default
        // fallbackLng: "id", // TODO: enable fallback to ID when translation is complete
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
