var defaultState = {
    message: ''
};
export default function (state = defaultState, action) {
    switch (action.type) {
        case 'SET_ALERT':
            return { ...state, message: action.payload };
        case 'RESET_ALERT':
            return { ...state, message: '' };
        default:
            return state;
    }
}
