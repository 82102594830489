import React, {Component} from 'react';
import { Grid, Button , TextField, Typography } from '@material-ui/core';

import { contactUs } from '../../actions/ContactUsScreen/index.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ContactUsFormCmp extends Component {
    state = {
        name : '',
        email: '',
        message: ''
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.contactUs(this.state.name, this.state.email, this.state.message);
    }

    render() {
        return (
            <div>
                <Typography variant="h6">We really appreciate you taking time to give us input</Typography>
                <form onSubmit={this.handleSubmit}>
                    <Grid container item justify="center">
                        <TextField
                            id="name"
                            name="name"
                            autoComplete="name"
                            label="Name"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="outlined-email-input"
                            label="Email"
                            type="email"
                            name="email"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid container item justify="center">
                        <TextField
                            id="message"
                            name="message"
                            autoComplete="message"
                            label="Message"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline={true}
                            rows={4}
                            onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={this.handleSubmit.bind(this)}>
                        Send Message
                    </Button>
                </form>
            </div>
        );
    }
    }

function mapStateToProps(state, ownProps) {
    return {
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        contactUs
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ContactUsFormCmp);
