export function startSearch(resp) {
    return {
        type: 'START_SEARCH',
        payload: resp
    };
}

export function gMapIsReady() {
    return {
        type: 'GMAP_READY',
    };
}
