import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

import { logout, getProfile, convertToAgent, handleChangeProfilePicture } from '../../actions/AuthScreen/index.js';

import { Button, Grid, Typography, TextField } from '@material-ui/core';
import AuthSrvc from '../../service/AuthSrvc.js';
import EditUserProfileFormCmp from '../EditUserProfileFormCmp/EditUserProfileFormCmp.js';

import Logo from '../../assets/logo/logo_transparent.png';
import { setAlert } from '../../actions/Common/index.js';

class AuthBtnGroupsCmp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            agentInfo: {
                firstName: '',
                lastName: ''
            }
        }
        // TODO: add loading state
        this.props.getProfile();
    }

    logout() {
        this.props.logout();
    }

    gotToAgentSignup() {
        this.props.history.push('/auth/agent');
    }

    handleAgentInputChange = (event) => {
        const newAgentInfo = { ...this.state.agentInfo };
        if (event.target.name === 'firstName') {
            newAgentInfo.firstName = event.target.value;
            this.setState({ agentInfo: newAgentInfo });
        }
        if (event.target.name === 'lastName') {
            newAgentInfo.lastName = event.target.value;
            this.setState({ agentInfo: newAgentInfo });
        }
    }

    handleChangeProfilePicture(event) {
        const newVal = event.target.files[0]
        this.props.handleChangeProfilePicture(newVal);
        this.props.setAlert("Profile picture saved")
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authStates.isLoggedIn && !this.props.authStates.isLoggedIn) {
            this.props.history.push('/auth');
        }
    }

    render() {
        let profilePictureThumbnail;
        if (this.props.authStates && this.props.authStates.authInfo.profilePictureThumbnail) {
            profilePictureThumbnail = this.props.authStates.authInfo.profilePictureThumbnail;
        } else {
            profilePictureThumbnail = Logo;
        }
        return (
            <div>
                <Grid container justify="center">
                    <Grid container direction="column" justify="center" alignItems="center" spacing={2} item xs={12}>
                        <Grid item container justify="center">
                            <Grid item>
                                <Typography variant="h5">
                                    You're logged in!
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container justify="center">
                            <Grid item>
                                <img alt="profile_picture"
                                    src={profilePictureThumbnail}
                                    style={{ height: 250, minWidth: 250, cursor: "pointer", borderRadius: "50%" }}></img>
                            </Grid>
                        </Grid>
                        <Grid item container justify="center">
                            <Grid item>
                                <TextField
                                    id="profile-picture"
                                    className={this.state.classes.textField}
                                    type="file"
                                    variant="outlined"
                                    onChange={this.handleChangeProfilePicture.bind(this)}
                                    inputProps={{ accept: "image/*" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justify="center">
                            <EditUserProfileFormCmp/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" size="large" onClick={this.logout.bind(this)}>
                                Logout
                            </Button>
                        </Grid>
                        {
                            !AuthSrvc.isAgent &&
                            <Grid container justify="center" item xs={10} style={{ marginTop: 25 }}>
                                <Button variant="contained" color="primary" size="large" onClick={this.gotToAgentSignup.bind(this)}>
                                    Be an Agent!
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
};

function mapStateToProps(state) {
    return {
        authStates: state.authStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        logout,
        getProfile,
        convertToAgent,
        handleChangeProfilePicture,
        setAlert
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(AuthBtnGroupsCmp));
