let defaultState = {
    propertyResultData: [],
    propertyGeometries: [],
    priceMin: -1,
    priceMax: -1,
    propertyType: undefined,
    numBed: -1,
    numBath: -1,
    amenities: ''
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case 'FETCH_PROPERTY_DATA':
            return { ...state, propertyResultData: action.payload.map((obj) => obj.properties), propertyGeometries: action.payload.map((obj) => obj.geometry) };
        case 'SELECT_PRICE_MIN':
            return { ...state, priceMin: action.payload }
        case 'SELECT_PRICE_MAX':
            return { ...state, priceMax: action.payload }
        case 'SELECT_PROPERTY_TYPE':
            return { ...state, propertyType: action.payload }
        case 'SELECT_BED_NUMBER':
            return { ...state, numBed: action.payload }
        case 'SELECT_BATH_NUMBER':
            return { ...state, numBath: action.payload }
        case 'SELECT_AMENITIES':
            return { ...state, amenities: action.payload }
        default:
            return state;
    }
}
