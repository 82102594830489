import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from '@material-ui/core';

import PropTypes from 'prop-types';

import { getSingleMessage, readSingleMessage } from '../../actions/MessageScreen/index.js';

class MessageInfoCmp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: PropTypes.object.isRequired,
        }
        this.props.getSingleMessage(this.props.messageId);
        this.props.readSingleMessage(this.props.messageId);
    }

    // TODO: move to DateUtilSrvc
    convertDate = (date) => {
        const dateObj = (new Date(date));
        const dateString = dateObj.toDateString();
        const timeString = dateObj.toLocaleTimeString();
        return `${dateString} ${timeString}`
    }

    render() {
        return (
            <div>
                <Typography variant="subtitle1" style={{ marginLeft: 10 }}>{this.convertDate(this.props.messageScreenStates.singleMessageData.createdAt)}</Typography>
                <Typography variant="subtitle1" style={{ marginLeft: 10 }}>Name: {this.props.messageScreenStates.singleMessageData.clientName}</Typography>
                <Typography variant="subtitle1" style={{ marginLeft: 10 }}>Email: {this.props.messageScreenStates.singleMessageData.clientEmail}</Typography>
                <Typography variant="subtitle1" style={{ marginLeft: 10 }}>Phone: {this.props.messageScreenStates.singleMessageData.clientPhone}</Typography>
                { this.props.messageScreenStates.singleMessageData.clientMessage && <Typography variant="subtitle1" style={{ marginLeft: 10 }}>Message: {this.props.messageScreenStates.singleMessageData.clientMessage}</Typography>}
                { this.props.messageScreenStates.singleMessageData.propertyFormattedAddress && <Typography variant="subtitle1" style={{ marginLeft: 10 }}>Requested property: {this.props.messageScreenStates.singleMessageData.propertyFormattedAddress}</Typography>}
            </div>
        )

    }
}

function mapStateToProps(state, ownProps) {
    return {
        messageScreenStates: state.messageScreenStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getSingleMessage,
        readSingleMessage
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(MessageInfoCmp);
