export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateIndonesianPhoneNumber(phoneNumber) {
    var re = /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/;
    return re.test(String(phoneNumber).toLowerCase());
}

export function sanitizeIndonesianPhoneNumber(phoneNumber) {
    if (phoneNumber) {
        return phoneNumber.replace(/^0/, '+62');
    }
    return phoneNumber;
}
