import ApiSrvc from '../../service/ApiSrvc';

const getEnumsQuery = () =>
    `query {
        allEnums {
            fieldName
            enums {
                key
                value
            }
        }
    }`;

function parseEnums(response) {
    let enumsMap = {};
    for (const enumTypeMap of response.data.allEnums) {
        const enumValueMap =
            enumTypeMap["enums"].reduce(
                function(map, obj) {
                    map[obj["key"]] = obj["value"];
                    return map;
                }, {}
            )
        enumsMap[enumTypeMap['fieldName']] = enumValueMap;
    }
    return enumsMap;
}

function initEnum() {
    const query = getEnumsQuery();
    return ApiSrvc.makeQuery(query)
        .then((response) => {
            console.log("Enums are fetched", response)
            return parseEnums(response);
        })
        .catch((error) => {
            console.error(error, "[getEnums] error to get enums");
        });
}

class EnumManager {
    enumMapCached = {}

    constructor() {
        if (!EnumManager.instance) {
            initEnum().then((response) => {
                this.enumMapCached = response;
            });
            EnumManager.instance = this;
        }
        return EnumManager.instance;
    }

    getListingTypeEnumMap() {
        return this.enumMapCached['LISTING_TYPE'];
    }

    getListingTypeStr(listingTypeValue) {
        const listingTypeMap = this.getListingTypeEnumMap()
        return Object.keys(listingTypeMap).find(key => listingTypeMap[key] === listingTypeValue);
    }

    getListingStatusEnumMap() {
        return this.enumMapCached['LISTING_STATUS'];
    }

    getListingStatusStr(listingStatusValue) {
        const listingStatusMap = this.getListingStatusEnumMap()
        return Object.keys(listingStatusMap).find(key => listingStatusMap[key] === listingStatusValue);
    }

    getPropertyTypeEnumMap() {
        return this.enumMapCached['PROPERTY_TYPE'] || {};
    }

    getPropertyTypeStr(propertyTypeValue) {
        const propertyTypeMap = this.getPropertyTypeEnumMap()
        return Object.keys(propertyTypeMap).find(key => propertyTypeMap[key] === propertyTypeValue);
    }
}

const EnumManagerInstance = new EnumManager();

export default EnumManagerInstance;