import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Grid, Typography } from '@material-ui/core';

import PropertyListCmp from '../PropertyListCmp/PropertyListCmp.js';
import { getFavorites } from '../../actions/FavoriteCmp/index.js';

class FavoriteScreen extends Component {
    constructor(props) {
        super(props);

        // found propertyId in URL (default: go to /favorites for now, need refactor)
        if (this.props.match.params.type === 'favorites' && this.props.match.params.id) {
            this.props.history.push('/dash/favorites');
        }
        this.props.getFavorites();
    }
    render() {
        let content;
        if (this.props.favoriteStates.propertyResultData.length > 0) {
            content = (
                <PropertyListCmp data={this.props.favoriteStates.propertyResultData} />
            )
        } else {
            content = (
                <Grid container item justify="center">
                    <Typography variant="h5" style={{marginTop: 20}}>Start adding some favorites</Typography>
                </Grid>
            )
        }
        return (
            <div>
                <Grid container item direction="row">
                    <Grid item xs={12}>
                        {content}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        favoriteStates: state.favoriteStates,
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getFavorites
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(FavoriteScreen));
