var defaultState = {
    favoriteProperties: {
        // 'propertyInfoId': true
    },
    propertyResultData: [],
    // TODO: refactor propertyList into re-usable component (favorites + resultScreen)
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case 'ADD_FAVORITE':
            const propertyData = action.payload;
            const newFavoriteProperties = state.favoriteProperties;
            newFavoriteProperties[propertyData.propertyInfoId] = true;
            state.propertyResultData.push(propertyData);
            return { ...state, favoriteProperties: newFavoriteProperties };
        case 'REMOVE_FAVORITE':
            const propertyInfoId = action.payload;
            if (state.favoriteProperties[propertyInfoId]) {
                delete state.favoriteProperties[propertyInfoId];
                // remove property from array (TODO: perf hit)
                state.propertyResultData = state.propertyResultData
                    .filter((prop) => prop.propertyInfoId !== propertyInfoId);
                return state;
            }
            return state;
        case 'VERIFIED_LOGIN':
            const favoritePropertiesArr = action.payload.favoritePropertiesArr;
            // just in case, clear first
            state.propertyResultData = [];
            favoritePropertiesArr.forEach((elem) => {
                const propId = elem.propertyInfoId;
                state.propertyResultData.push(elem);
                state.favoriteProperties[propId] = true;
            });
            return state;
        case 'GET_FAVORITES':
            action.payload.forEach(property => {
                state.favoriteProperties[property.propertyInfoId] = true;
            });
            return { ...state, propertyResultData: action.payload };
        case 'LOGOUT':
            return defaultState;
        default:
            return state;
    }
}
