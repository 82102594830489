import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveProperty, saveAsDraftProperty, savePropertyPicture, deletePropertyPicture, prefillEditProperty, prefillingProperty, resetState } from '../../actions/CreatePropertyScreen/index.js';
import { updatePropertyPicture } from '../../actions/CreatePropertyScreen/index.js';
import { setAlert } from '../../actions/Common/index.js';
import { withRouter } from 'react-router';
import AuthSrvc from '../../service/AuthSrvc';
import { FormValidator, FieldRuleValidation } from '../../util/Validator/FormValidator.js';

// Components
import FilterSelectorCmp from '../FilterSelectorCmp/FilterSelectorCmp.js';

// material-ui
import { Grid, TextField, Button, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import EnumManagerInstance from '../../util/Enum/EnumManager.js';
import { PROPERTY_TYPE, LISTING_TYPE, LISTING_STATUS } from '../../util/Enum/EnumConstants.js';

import CreatePropertyPhotosCmp from '../CreatePropertyPhotosCmp/CreatePropertyPhotosCmp.js';
import AdditionalFiltersCmp from '../AdditionalFiltersCmp/AdditionalFiltersCmp.js';

// specify which one need to have <Select /> instead of <TextField />
// const NUMBERS_INPUT = ['price', 'bedroom', 'bathroom', 'buildingSizeM2', 'lotSizeM2'];
// const TEXT_INPUT = ['description'];
// const SELECTOR_INPUT = ['propertyType', 'listingType'];
// const INPUT_ORDERS = [...SELECTOR_INPUT, ...NUMBERS_INPUT, ...TEXT_INPUT];
const propertyAddressKeys = ['administrativeAreaLevel4', 'administrativeAreaLevel3', 'administrativeAreaLevel2', 'administrativeAreaLevel1', 'streetNumber', 'postalCode', 'route', 'country', 'formattedAddress'];
const dbKeyMapping = {
    formattedAddress: 'formatted_address',
    administrativeAreaLevel4: 'administrative_area_level_4',
    administrativeAreaLevel3: 'administrative_area_level_3',
    administrativeAreaLevel2: 'administrative_area_level_2',
    administrativeAreaLevel1: 'administrative_area_level_1',
    country: 'country',
    postalCode: 'postal_code',
    streetNumber: 'street_number',
    route: 'route'
};

class CreatePropertyScreen extends Component {
    validator = undefined;
    validationResult = undefined;
    constructor(props) {
        super(props);
        this.validator =
            new FormValidator(
                [
                    new FieldRuleValidation('price', (value) => parseInt(value) > 0, true),
                    new FieldRuleValidation('bedroom', (value) => parseInt(value) > -1, true),
                    new FieldRuleValidation('bathroom', (value) => parseInt(value) > -1, true),
                    new FieldRuleValidation('spaceSizeM2', (value) => parseInt(value) > 0, true),
                ]
            )
        this.state = {
            classes: PropTypes.object.isRequired,
            isCreateMode: true,
            shouldShowAutoComplete: true,
            propertyAddress: {},
            formattedAddress: '',
            listingDetails: {
                price: undefined,
                bedroom: undefined,
                bathroom: undefined,
                propertyType: EnumManagerInstance.getPropertyTypeEnumMap()[PROPERTY_TYPE.HOUSE], // House by default
                listingType: EnumManagerInstance.getListingTypeEnumMap()[LISTING_TYPE.RENT], // RENT by default
                spaceSizeM2: undefined,
                floor: undefined,
                minLeaseDurationMonth: undefined,
                securityDeposit: undefined,
                location: '', // empty for now
                leaseTerms: undefined,
                description: undefined,
                unitNumber: undefined,
                amenities: {}
            },
            isGoogleMapReady: false
        };
        if (props.match.url.indexOf('create') > -1) {
            this.state.isCreateMode = true;
            this.state.shouldShowAutoComplete = true;
            this.props.resetState();
        }
        if (props.match.params.id) {
            // id exist (edit mode)
            const propertyId = props.match.params.id;
            this.state.isCreateMode = false;
            this.state.shouldShowAutoComplete = false;
            this.props.resetState();
            this.props.prefillingProperty();
            this.props.prefillEditProperty(propertyId);
        }
        this.shouldRedirect();
        window.addthis.init();
        window.addthis.toolbox();
        if (typeof window.addthis.layers.refresh === "function") {
            window.addthis.layers.refresh();
        }
        // reset scroll position
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isCreateMode && (prevProps.createScreenStates.toEditProperty !== this.props.createScreenStates.toEditProperty)) {
            Object.keys(this.props.createScreenStates.toEditProperty).forEach((key) => {
                const newVal = this.props.createScreenStates.toEditProperty[key];
                if (propertyAddressKeys.indexOf(key) !== -1) {
                    this.updatePropertyAddress(key, newVal);
                } else {
                    this.updateState(key, newVal);
                }
            });
        }
        if (!prevProps.createScreenStates.isSaved && this.props.createScreenStates.isSaved) {
            this.props.setAlert('Your changes has been saved');
        }
        if (prevProps.createScreenStates.isUploading && !this.props.createScreenStates.isUploading) {
            this.props.setAlert('Property picture saved');
        }

        // handle initializing google when it is not triggered by componentDidMount
        if (!prevProps.searchInputStates.isGoogleMapReady && this.props.searchInputStates.isGoogleMapReady) {
            this.initGoogleMapSearch();
        }
    }

    componentDidMount() {
        if (this.props.searchInputStates.isGoogleMapReady) {
            this.initGoogleMapSearch();
        }
    }

    shouldRedirect() {
        if (!AuthSrvc.isAgent) {
            this.props.setAlert('Please convert your user to an agent first!');
            this.props.history.push('/auth/agent');
        }
    }

    initGoogleMapSearch() {
        // Declare Options For Autocomplete
        var options = { types: ['address'], componentRestrictions: { country: ['ID',] } };

        // Initialize Google Autocomplete
        //eslint for window.global
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('google_autocomplete'),
            options);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect.bind(this));
        // Specify which data to return (less data->less $$)
        this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
        this.setState({ isGoogleMapReady: true });
    }

    // TODO: make it safe
    getAdminLevel = (obj) => {
        let res = {};
        obj.address_components.forEach((addr) => {
            const key = addr.types[0];
            res[key] = addr.long_name;
        });
        res.lat = obj.geometry.location.lat();
        res.lng = obj.geometry.location.lng();
        res.formatted_address = obj.formatted_address;
        return res;
    }

    handlePlaceSelect = () => {
        const addressObject = this.autocomplete.getPlace();
        const parsedAddressObject = this.getAdminLevel(addressObject);
        if (!parsedAddressObject['administrative_area_level_4']) {
            this.props.setAlert("Please put more detailed address. Ex: 'Jalan Cahaya No.1' instead of 'Jalan Cahaya'")
        } else {
            this.setState({ propertyAddress: parsedAddressObject, formattedAddress: addressObject.formatted_address });
            // if create mode, save as draft. Do nothing otherwise (let user click submit button).
            if (this.state.isCreateMode) {
                this.saveAsDraftProperty();
            }
            this.setState({ isCreateMode: false, shouldShowAutoComplete: false})
        }
        // Clear autocomplete field
        const autoCompleteCmp = document.getElementById('google_autocomplete');
        autoCompleteCmp.value = "";
    }

    handlePriceChange = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('price', newVal);
        }
    };

    handleListingTypeChange = (newVal) => {
        this.updateState('listingType', EnumManagerInstance.getListingTypeEnumMap()[newVal]);
    }

    handlePropertyTypeChange = (newVal) => {
        this.updateState('propertyType', EnumManagerInstance.getPropertyTypeEnumMap()[newVal]);
    }

    handleBedroomChange = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('bedroom', newVal);
        }
    }

    handleBathroomChange = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('bathroom', newVal);
        }
    }

    handleUnitNumberChange = (event) => {
        const newVal = event.target.value;
        this.updateState('unitNumber', newVal);
    }

    handleSpaceSizeM2Change = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('spaceSizeM2', newVal);
        }
    };

    handleFloorChange = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('floor', newVal);
        }
    };

    handleMinLeaseDurationMonthChange = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('minLeaseDurationMonth', newVal);
        }
    };

    handleSecurityDepositChange = (event) => {
        const newVal = event.target.value;
        if (newVal >= 0) {
            this.updateState('securityDeposit', newVal);
        }
    };

    handleLeaseTermsChange = (event) => {
        const newVal = event.target.value;
        this.updateState('leaseTerms', newVal);
    }

    // TODO: avoid possible perf hit (re-render every setState)
    // https://stackoverflow.com/questions/50819260/react-input-onchange-lag
    handleDescriptionChange = (event) => {
        const newVal = event.target.value;
        this.updateState('description', newVal);
    }

    handleAmenitiesChange(amenities) {
        this.updateState('amenities', amenities);
    }

    handleUploadPicture = (event) => {
        const newVal = event.target.files[0]
        this.props.savePropertyPicture(this.props.createScreenStates.toEditProperty, newVal)
    }

    handleDeletePicture = (picture) => {
        this.props.deletePropertyPicture(this.props.createScreenStates.toEditProperty, picture.propertyPictureId)
    }

    handleUpdatePictureDesc = (singlePropertyPictureObj) => {
        // make sure to create new obj, otherwise change will be propaged to children
        const toUpdate = {};
        toUpdate.propertyPictureId = singlePropertyPictureObj.propertyPictureId;
        toUpdate.description = singlePropertyPictureObj.description;
        this.props.updatePropertyPicture([toUpdate]);
    }

    handleUpdatePictureOrder = (propertyPicturesArray) => {
        const toUpdate = [];
        // add corresponding newOrder (index) attribute for backend
        propertyPicturesArray.forEach((p, index) => {
            // make sure to create new obj, otherwise change will be propaged to children
            const newObj = {};
            newObj.newPosition = index;
            newObj.propertyPictureId = p.propertyPictureId;
            toUpdate[index] = newObj;
        });
        this.props.updatePropertyPicture(toUpdate);
    }

    updatePropertyAddress = (key, newVal) => {
        const mappedKey = dbKeyMapping[key];
        const newState = this.state.propertyAddress;
        newState[mappedKey] = newVal;
        this.setState({
            propertyAddress: newState
        });
    }

    updateState = (key, newVal) => {
        const newState = this.state.listingDetails;
        newState[key] = newVal;
        this.setState({ listingDetails: newState });
        this.validationResult = this.validator.validate(newState);
    };

    saveAsDraftProperty() {
        if (this.state.isCreateMode) {
            this.props.saveAsDraftProperty(this.state.propertyAddress, this.state.listingDetails,);
        } else {
            this.props.saveAsDraftProperty(this.state.propertyAddress, {...this.state.listingDetails, propertyInfoId: this.props.createScreenStates.toEditProperty.propertyInfoId});
        }
    }

    saveProperty() {
        if (this.state.isCreateMode) {
            this.props.saveProperty(this.state.propertyAddress, this.state.listingDetails,);
        } else {
            this.props.saveProperty(this.state.propertyAddress, {...this.state.listingDetails, propertyInfoId: this.props.createScreenStates.toEditProperty.propertyInfoId});
        }
    }

    /**
     * if using arrow function (function expression), this (class) will be binded automatically
     *      submit = () => {} <..onClick={this.submit} />
     * if using class function (function declaration), this (class) will NOT be binded automatically
     *      submit(){} <..onClick={this.submit.bind(this)} />
     * however, for small perf benefit
     * use function declaration so no need to re-initialize function expression every render cycle
     */
    submit() {
        if (!this.validationResult) {
            this.validationResult = this.validator.validate(this.state.listingDetails)
        }
        if (this.validationResult.isCompleteValid) {
            this.saveProperty();
            this.props.setAlert("Listing is created successfully: " + this.state.formattedAddress);
            this.props.history.push('/dash/listing');
        } else {
            this.props.setAlert("Please fill all fields properly");
        }
    }

    saveAsDraft() {
        this.saveAsDraftProperty();
        this.props.history.push('/dash/listing');
    }

    cancel() {
        this.props.history.goBack();
    }

    handleToEditPropertyAddress() {
        this.setState({ shouldShowAutoComplete: true });
    }

    handleBlurAutoComplete() {
        if (!this.state.isCreateMode) {
            this.setState({ shouldShowAutoComplete: false });
        }
    }

    render() {
        return (
            <div>
                {/* <form className={this.state.classes.container} noValidate autoComplete="off" style={{width: '100%'}}> */}
                <Grid container spacing={3}>
                    <Grid item container xs={12} md={6} justify="center" style={{padding: 30}}>
                        <Grid item container direction="column">
                            <Grid item container justify="center" style={{paddingBottom: 20}}>
                                <Typography variant="h4">Property Information</Typography>
                            </Grid>
                            <Grid item style={{ display: `${this.state.shouldShowAutoComplete ? "" : "none"}` }}>
                                <TextField
                                    id="google_autocomplete"
                                    label="Find Address"
                                    className={this.state.classes.textField}
                                    margin="normal"
                                    fullWidth
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    onBlur={this.handleBlurAutoComplete.bind(this)}
                                />
                            </Grid>
                            { /* Show below block only during edit state */
                                !this.state.isCreateMode && this.props.createScreenStates.toEditProperty &&
                                <Grid>
                                    <Grid item style={{ display: `${!this.state.shouldShowAutoComplete ? "" : "none"}` }}>
                                        <span>Current Saved Address:</span>
                                        <TextField
                                            id="current_saved_address"
                                            className={this.state.classes.textField}
                                            /* require to check state formatted address first, in case of edit property address, needs to show fast. */
                                            value={this.state.formattedAddress || this.props.createScreenStates.toEditProperty.formattedAddress || ''}
                                            margin="normal"
                                            fullWidth
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            disabled
                                            onClick={this.handleToEditPropertyAddress.bind(this)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="unit_number"
                                            label="Unit Number (Optional)"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.unitNumber || ''}
                                            margin="normal"
                                            fullWidth
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleUnitNumberChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FilterSelectorCmp
                                            filterName="Listing Type"
                                            onChange={this.handleListingTypeChange}
                                            currentVal={EnumManagerInstance.getListingTypeStr(this.state.listingDetails.listingType) || LISTING_TYPE.RENT}
                                            choices={LISTING_TYPE} // Only enable rent right now
                                            extraStyle={{ marginLeft: 10, marginRight: 10 }} />
                                    </Grid>
                                    <Grid item>
                                        <FilterSelectorCmp
                                            filterName="Property Type"
                                            onChange={this.handlePropertyTypeChange}
                                            currentVal={EnumManagerInstance.getPropertyTypeStr(this.state.listingDetails.propertyType) || PROPERTY_TYPE.HOUSE}
                                            choices={PROPERTY_TYPE}
                                            extraStyle={{ marginLeft: 10, marginRight: 10 }} />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={this.validationResult && !this.validationResult.result['price'].isValid}
                                            id="price"
                                            label="Price (Rp)"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.price || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handlePriceChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={this.validationResult && !this.validationResult.result['bedroom'].isValid}
                                            id="bedroom"
                                            label="Bedroom"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.bedroom || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleBedroomChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={this.validationResult && !this.validationResult.result['bathroom'].isValid}
                                            id="bathroom"
                                            label="Bathroom"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.bathroom || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleBathroomChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={this.validationResult && !this.validationResult.result['spaceSizeM2'].isValid}
                                            id="space_size_m2"
                                            label="Size (m2)"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.spaceSizeM2 || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleSpaceSizeM2Change}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="floor"
                                            label="Floor (Optional)"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.floor || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleFloorChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="min_lease_duration_month"
                                            label="Minimum Lease Duration (month)"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.minLeaseDurationMonth || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleMinLeaseDurationMonthChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="security_deposit"
                                            label="Security Deposit (Rp)"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.securityDeposit || ''}
                                            margin="normal"
                                            fullWidth
                                            type="number"
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleSecurityDepositChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="lease_terms"
                                            label="Lease Terms"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.leaseTerms || ''}
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            rowsMax={6}
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleLeaseTermsChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="description"
                                            label="Description"
                                            className={this.state.classes.textField}
                                            value={this.state.listingDetails.description || ''}
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            rowsMax={6}
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            onChange={this.handleDescriptionChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {/* Need to click Apply to save it into state (for now) */}
                                        <AdditionalFiltersCmp
                                            handleAmenitiesChange={this.handleAmenitiesChange.bind(this)}
                                            initialData={this.state.listingDetails.amenities}
                                            isCreateMode
                                            />
                                    </Grid>
                                    <Grid item className="addthis_inline_share_toolbox_4zkt"
                                        data-url={window.location.href.replace('/edit', '')}
                                        data-title="Check out this interesting property!"
                                        style={{ marginTop: 10 }}>
                                    </Grid>
                                    <Grid item container justify="center">
                                        <Grid item container xs={4} justify="center">
                                            <Button variant="contained" style={{ marginTop: 10 }} color="primary" size="large" onClick={this.submit.bind(this)}>Submit</Button>
                                        </Grid>
                                        {
                                            this.props.createScreenStates.toEditProperty.listingStatus === EnumManagerInstance.getListingStatusEnumMap()[LISTING_STATUS.INACTIVE] &&
                                            <Grid item container xs={4} justify="center">
                                                <Button variant="contained" style={{ marginTop: 10 }} color="primary" size="large" onClick={this.saveAsDraft.bind(this)}>Save as draft</Button>
                                            </Grid>
                                        }
                                        <Grid item container xs={4} justify="center">
                                            <Button variant="contained" style={{ marginTop: 10 }} color="primary" size="large" onClick={this.cancel.bind(this)}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} style={{ padding: 30 }}>
                        <CreatePropertyPhotosCmp
                            isUploading={this.props.createScreenStates.isUploading}
                            isReadyToUpload={!this.state.isCreateMode && this.props.createScreenStates.toEditProperty}
                            onPictureUpload={this.handleUploadPicture.bind(this)}
                            onPictureDelete={this.handleDeletePicture.bind(this)}
                            onPictureUpdateDesc={this.handleUpdatePictureDesc.bind(this)}
                            onPictureUpdateOrder={this.handleUpdatePictureOrder.bind(this)}
                            propertyPictures={this.props.createScreenStates.toEditProperty.propertyPictures || []}
                        />
                    </Grid>
                </Grid>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.homeStates,
        searchInputStates: state.searchInputStates,
        authStates: state.authStates,
        createScreenStates: state.createScreenStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        saveProperty,
        saveAsDraftProperty,
        savePropertyPicture,
        deletePropertyPicture,
        updatePropertyPicture,
        prefillEditProperty,
        prefillingProperty,
        resetState,
        setAlert
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CreatePropertyScreen));
