import ApiSrvc from '../../service/ApiSrvc';

export function postFetchPropertyRelatedInfo(propertyInfoId, isInfoRequested) {
    return {
        type: 'GET_PROPERTY_RELATED_INFO',
        payload: { 'propertyInfoId': propertyInfoId, 'isInfoRequested': isInfoRequested }
    }
}

export function postPropertyInfoRequestSent(propertyInfoId) {
    return {
        type: 'PROPERTY_INFO_REQUEST_SENT',
        payload: { 'propertyInfoId': propertyInfoId }
    }
}

export function postFetchPropertyById(data) {
    return {
        type: 'POST_FETCH_PROPERTY_BY_ID',
        payload: data[0].node.properties
    }
}

export function prefillPropertyData(data) {
    return {
        type: 'PREFILL_PROPERTY_DATA',
        payload: data
    }
}

export function postFetchSimilarPropertyInfos(data) {
    return {
        type: 'FETCH_SIMILAR_PROPERTY_INFOS_DATA',
        payload: data.map((obj) => obj.node.properties) || []
    }
}

function buildContactAgentMutation(propertyInfoId, clientName, clientPhone, clientEmail, clientMessage) {
    const query = `
        mutation contactAgent {
            contactAgent(agentMessageInput: {
                propertyInfoId: "${propertyInfoId}",
                clientName: "${clientName}",
                clientPhone: "${clientPhone}",
                clientEmail: "${clientEmail}",
                clientMessage: "${clientMessage}",
                agentMessageType: 1
            }) {
                ok
            }
        }
    `;
    return query;
}

function buildPropertyRelatedInfoQuery(propertyInfoId) {
    const query = `{
        userAndPropertyRelatedInfos(
            propertyInfoIds: ["${propertyInfoId}"]
            ) {
                propertyInfoId
                isInfoRequested
            }
        }`;
    return query;
}

const buildFetchPropertyByIdQuery = (propertyId) => `
    {
        allPropertyInfos(
            propertyInfoIds: "${propertyId}"
            ) {
            edges{
                node {
                    properties {
                        administrativeAreaLevel3
                        propertyInfoId
                        formattedAddress
                        unitNumber
                        propertyType
                        listingType
                        bathroom
                        bedroom
                        price
                        spaceSizeM2
                        floor
                        description
                        minLeaseDurationMonth
                        securityDeposit
                        leaseTerms
                        amenities
                        userProfile {
                            firstName
                            lastName
                            email
                            phoneNumber
                            profilePictureThumbnail
                        }
                        propertypictureSet {
                            propertyPictureId
                            thumbnail
                            description
                            original
                        }
                    }
                }
            }
        }
    }
`

const buildFetchSimilarPropertyInfos = (propertyId, numberOfListings) => `
    {
        similarPropertyInfos(
            propertyInfoId: "${propertyId}",
            first: ${numberOfListings}
            ) {
            edges{
                node {
                    properties {
                        administrativeAreaLevel3
                        propertyInfoId
                        formattedAddress
                        propertyType
                        listingType
                        bathroom
                        bedroom
                        price
                        spaceSizeM2
                        userProfile {
                            firstName
                            lastName
                            email
                            phoneNumber
                        }
                        propertypictureSet {
                            propertyPictureId
                            thumbnail
                            original
                        }
                    }
                }
            }
        }
    }
`

export function fetchPropertyRelatedInfo(propertyInfoId) {
    return function (dispatch, getState) {
        const query = buildPropertyRelatedInfoQuery(propertyInfoId);

        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                const isInfoRequested = response.data.userAndPropertyRelatedInfos[0].isInfoRequested;
                dispatch(postFetchPropertyRelatedInfo(propertyInfoId, isInfoRequested))
            })
            .catch((error) => {
                console.error(error, "[fetchPropertyRelatedInfo] error fetching data");
            });
    }
}

export function contactAgentForPropertyInfo(propertyInfoId, clientName, clientPhone, clientEmail, clientMessage) {
    return function (dispatch, getState) {
        const query = buildContactAgentMutation(propertyInfoId, clientName, clientPhone, clientEmail, clientMessage);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    console.log('Request sent', response);
                    dispatch(postPropertyInfoRequestSent(propertyInfoId))
                }
            })
            .catch((error) => {
                console.error(error, "[contactAgentForPropertyInfo] error saving property");
            });
    }
}

export function fetchPropertyById(propertyInfoId) {
    return function (dispatch, getState) {
        const query = buildFetchPropertyByIdQuery(propertyInfoId);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    const data = response.data.allPropertyInfos.edges;
                    dispatch(postFetchPropertyById(data));
                }
            })
            .catch((error) => {
                console.error(error, "[propertyPage] error fetching property");
            });
    }
}

export function fetchSimilarPropertyInfos(propertyInfoId) {
    return function (dispatch, getState) {
        const query = buildFetchSimilarPropertyInfos(propertyInfoId, 8);
        return ApiSrvc.makeQuery(query)
            .then(function (response) {
                if (!response.errors) {
                    const data = response.data.similarPropertyInfos.edges;
                    dispatch(postFetchSimilarPropertyInfos(data));
                }
            })
            .catch((error) => {
                console.error(error, "[propertyPage] error fetching similar properties");
            });
    }
}
