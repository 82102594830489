import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { startSearch } from '../../actions/SearchInputCmp/index.js';
import { searchProperty } from '../../actions/ResultScreen/index.js';
// material-ui
import { TextField } from '@material-ui/core';

import { withRouter } from 'react-router';

import { setAlert } from '../../actions/Common/index.js';

class SearchInputCmp extends Component {
    componentDidMount = () => {
        // Declare Options For Autocomplete
        var options = {
            types: ['establishment','geocode'],
            componentRestrictions: {country: ['ID',]}
        };

        // Initialize Google Autocomplete
        //eslint for window.global
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('google_autocomplete'),
            options);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
            this.handlePlaceSelect.bind(this));
        // Specify which data to return (less data->less $$)
        this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'name'])
    }

    // TODO: make it safe
    getAdminLevel = (obj) => {
        let res = {};
        obj.address_components.forEach((addr) => {
            const key = addr.types[0];
            res[key] = addr.long_name;
            if (key === 'administrative_area_level_4') {
                res.isAdminLevel4Present = true
            }
        });
        res.lat = obj.geometry.location.lat();
        res.lng = obj.geometry.location.lng();
        res.name = obj.name; // name of selected location
        return res;
    }

    handlePlaceSelect = () => {
        let addressObject = this.autocomplete.getPlace();
        if (!addressObject.address_components) {
            this.props.setAlert('Please select a region from the list');
            return;
        }
        let toSearch = this.getAdminLevel(addressObject);
        this.props.startSearch(toSearch);
        this.props.searchProperty();
        // if not on any property property page (search page)
        if (this.props.match.url && this.props.match.url.indexOf('result') === -1) {
            this.props.history.push('/result');
        }
        // if on property page, but on specific property (go back to result page)
        if (this.props.match.url && this.props.match.url.indexOf('result') !== -1 && this.props.match.params.id) {
            this.props.history.push('/result');
        }
    }

    render() {
        return (
            <TextField
                id="google_autocomplete"
                label={"Enter Location: " + (this.props.locationName || "")}
                placeholder={this.props.locationName || "ex: Kebayoran Lama / Universitas Tarumanegara"}
                margin="normal"
                variant="outlined"
                fullWidth
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.searchInputState
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        startSearch,
        searchProperty,
        setAlert
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(SearchInputCmp));
