export function changePropertySelectorCmpState(resp) {
    return {
        type: 'LISTING_SELECTOR_CHANGE',
        payload: resp
    };
}

export function resetPropertySelectorCmpState(resp) {
    return {
        type: 'LISTING_SELECTOR_RESET',
        payload: resp
    }
}
