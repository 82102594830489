import React, {Component} from 'react';
import { Grid, Button , TextField } from '@material-ui/core';
import { contactAgentForPropertyInfo } from '../../actions/PropertyPageScreen/index.js';
import { FormValidator, FieldRuleValidation } from '../../util/Validator/FormValidator.js';
import { validateEmail, validateIndonesianPhoneNumber, sanitizeIndonesianPhoneNumber } from '../../util/Validator/FieldValidator.js';
import { editUserProfile } from '../../actions/AuthScreen/index.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAlert } from '../../actions/Common/index.js';
import AuthSrvc from '../../service/AuthSrvc';
import WhatsappLogo from '../../assets/logo/whatsapp_logo_transparent.png';

class RequestPropertyInfoForm extends Component {
    validator = undefined;
    validationResult = undefined;

    constructor(props) {
        super(props);
        this.validator =
            new FormValidator(
                [
                    new FieldRuleValidation('firstName', (value) => value, true),
                    new FieldRuleValidation('email', validateEmail, true),
                    new FieldRuleValidation('phoneNumber', validateIndonesianPhoneNumber, true),
                ]
            )
        this.state = {
            firstName: props.authStates.authInfo.firstName,
            phoneNumber: props.authStates.authInfo.phoneNumber,
            email: props.authStates.authInfo.userEmail,
            message: '',
            propertyInfoId: props.propertyInfoId
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        const newStateToValidate = { ...this.state, [event.target.name]: event.target.value };
        this.validationResult = this.validator.validate(newStateToValidate);
    }

    handleSaveUserProfile() {
        if (AuthSrvc.isLoggedIn) {
            const updateProfileState = {}
            if (!this.props.authStates.authInfo.firstName && this.state.firstName) {
                updateProfileState.firstName = this.state.firstName
            }
            if (!this.props.authStates.authInfo.phoneNumber && this.state.phoneNumber) {
                updateProfileState.phoneNumber = this.state.phoneNumber
            }
            // Check updateProfileState is not empty
            if (Object.entries(updateProfileState).length !== 0) {
                this.props.editUserProfile(updateProfileState);
            }
        }
    }

    handleSubmit(isViaWhatsapp) {
        if (!this.validationResult) {
            this.validationResult = this.validator.validate(this.state)
        }
        if (this.validationResult.isCompleteValid) {
            if (isViaWhatsapp) {
                // TODO: convert to agent number
                const phoneNumber = "16504477788";
                var textParam = "text=" + window.location.href + "  Hello, I'm interested in your listing"
                const whatsappUri = decodeURI("https://wa.me/" + phoneNumber + "?" + textParam)
                window.open(whatsappUri);
            }
            this.props.contactAgentForPropertyInfo(this.props.propertyInfoId, this.state.firstName, sanitizeIndonesianPhoneNumber(this.state.phoneNumber), this.state.email, this.state.message);
            this.props.setAlert("Request submitted")
            // Try to save this profile if it's possible
            this.handleSaveUserProfile()
        } else {
            this.props.setAlert("Please fill all fields properly")
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authStates.authInfo.userEmail !== this.props.authStates.authInfo.userEmail) {
            this.setState({
                firstName: this.props.authStates.authInfo.firstName,
                phoneNumber: this.props.authStates.authInfo.phoneNumber,
                email: this.props.authStates.authInfo.userEmail
            })
        }
    }

    render() {
        if (this.props.isInfoRequested) {
            return (
                <span>
                    You have requested more info about this property.
                </span>
            )
        }
        return (
            <form>
                <Grid item container>
                    <Grid container item justify="center">
                        <Grid item xs={10}>
                            <TextField
                                error={this.validationResult && !this.validationResult.result['firstName'].isValid}
                                id="firstName"
                                name="firstName"
                                value={this.state.firstName}
                                autoComplete="firstName"
                                label="Name"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange.bind(this)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify="center">
                        <Grid item xs={10}>
                            <TextField
                                error={this.validationResult && !this.validationResult.result['email'].isValid}
                                id="outlined-email-input"
                                label="Email"
                                type="email"
                                name="email"
                                value={this.state.email}
                                autoComplete="email"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange.bind(this)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify="center">
                        <Grid item xs={10}>
                            <TextField
                                error={this.validationResult && !this.validationResult.result['phoneNumber'].isValid}
                                id="phoneNumber"
                                name="phoneNumber"
                                value={this.state.phoneNumber}
                                autoComplete="phoneNumber"
                                label="Phone"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange.bind(this)}
                                placeholder="+6281xxxxxxxx"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify="center">
                        <Grid item xs={10}>
                            <TextField
                                id="message"
                                name="message"
                                value={this.state.message}
                                autoComplete="message"
                                label="(Optional Message)"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                multiline={true}
                                rows={4}
                                onChange={this.handleChange.bind(this)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify="center" spacing={3} style={{marginTop: 20}}>
                        <Grid item xs={5}>
                            <Button fullWidth variant="contained" color="primary" size="medium" onClick={this.handleSubmit.bind(this, false)}>
                                Send Message
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button fullWidth variant="contained" color="primary" size="medium" onClick={this.handleSubmit.bind(this, false)}>
                                Send <img src={WhatsappLogo}
                                    alt="whatsapp_logo"
                                    style={{ paddingLeft: 5, height: 20, cursor: "pointer" }}
                                    onClick={this.handleSubmit.bind(this, true)} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
    }

function mapStateToProps(state, ownProps) {
    return {
        authStates: state.authStates
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        contactAgentForPropertyInfo,
        setAlert,
        editUserProfile
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(RequestPropertyInfoForm);
