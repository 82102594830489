import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { convertToAgent } from '../../actions/AuthScreen/index.js';

import AuthAgentFormCmp from '../AuthAgentFormCmp/AuthAgentFormCmp.js';

import { Grid, Typography } from '@material-ui/core';

import AuthSrvc from '../../service/AuthSrvc';

import { setAlert } from '../../actions/Common/index.js';

import { sanitizeIndonesianPhoneNumber } from '../../util/Validator/FieldValidator.js';

class AgentSignUpScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: PropTypes.object.isRequired,
            agentInfo: {}
        }
        if (AuthSrvc.isAgent) {
            this.props.history.push('/dash');
        }
        this.AuthSrvc = AuthSrvc;
    }

    convertToAgent() {
        this.props.convertToAgent(this.state.agentInfo);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.authStates.authInfo.agentId && this.props.authStates.authInfo.agentId) {
            this.props.history.push('/dash');
        }
    }

    handleAgentInputChange = (event) => {
        const newAgentInfo = { ...this.state.agentInfo };
        if (event.target.name === 'firstName') {
            newAgentInfo.firstName = event.target.value;
            this.setState({ agentInfo: newAgentInfo });
        }
        if (event.target.name === 'lastName') {
            newAgentInfo.lastName = event.target.value;
            this.setState({ agentInfo: newAgentInfo });
        }
        if (event.target.name === 'phoneNumber') {
            newAgentInfo.phoneNumber = sanitizeIndonesianPhoneNumber(event.target.value);
            this.setState({ agentInfo: newAgentInfo });
        }
    }

    render() {
        return (
            <Grid container justify="center" style={{paddingTop: 20}}>
                <Grid item container direction="column" xs={10} md={5}>
                    <Grid item container justify="center">
                        <Typography variant="h6">Please fill out the form below to be an agent</Typography>
                    </Grid>
                    <Grid item>
                        <AuthAgentFormCmp
                            handleAgentInputChange={this.handleAgentInputChange.bind(this)}
                            convertToAgent={this.convertToAgent.bind(this)}
                            setAlert={this.props.setAlert}/>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
};

function mapStateToProps(state) {
    return {
        authStates: state.authStates
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        convertToAgent,
        setAlert
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(AgentSignUpScreen));
