import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, TextField, CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';

import ReactDragListView from 'react-drag-listview';

class CreatePropertyPhotosCmp extends Component {
    constructor(props) {
        super(props);
        const data = [];
        for (let i = 1, len = 5; i < len; i += 1) {
            data.push({
                title: `rows${i}`
            });
        }
        this.state = {
            classes: PropTypes.object.isRequired,
            propertyPictures: props.propertyPictures,
            data
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.propertyPictures.length !== this.props.propertyPictures.length) {
            this.setState({propertyPictures: this.props.propertyPictures});
        }
    }

    handleUpdatePictureDesc(index) {
        const propToUpdate = this.state.propertyPictures[index];
        this.props.onPictureUpdateDesc(propToUpdate);
    }

    updatePictureDesc(index, event) {
        const newVal = event.target.value;
        const newPropertyPictures = this.state.propertyPictures;
        newPropertyPictures[index] = {...newPropertyPictures[index], description: newVal};
        this.setState({propertyPictures: newPropertyPictures});
    }

    render() {
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = that.state.propertyPictures;
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ propertyPictures: data });
                that.props.onPictureUpdateOrder(data);
            },
            nodeSelector: '.panel',
            handleSelector: '.panel'
        };

        return (
                <Grid container item direction="column">
                    <Grid item container justify="center" style={{ paddingBottom: 15 }}>
                        <Typography variant="h4">Property Photos</Typography>
                    </Grid>
                    {!this.props.isReadyToUpload &&
                        <Grid item container justify="center" style={{ paddingBottom: 15 }}>
                            <Typography variant="h5" color="textSecondary">Please enter an address first</Typography>
                        </Grid>
                    }
                    <Grid item container justify="center">
                        <Grid item container xs={10} >
                            <TextField
                                id="additional-picture"
                                className={this.state.classes.textField}
                                margin="normal"
                                type="file"
                                variant="outlined"
                                fullWidth
                                onChange={this.props.onPictureUpload}
                                inputProps={{ accept: "image/*" }}
                                disabled={!this.props.isReadyToUpload}
                            />
                        </Grid>
                        <Grid item container xs={2} alignItems="center" style={{marginLeft: 5}}>
                            {this.props.isUploading &&
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container style={{paddingTop: 15}}>
                        <Grid item container xs={12} justify="center">
                            <ReactDragListView {...dragProps}>
                                {this.state.propertyPictures.map((item, index) => (
                                    <ExpansionPanel key={item.propertyPictureId} className="panel">
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{item.description || `picture_${item.propertyPictureId.slice(0,4)}`}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <div><img alt="additional_picture_preview" src={item.thumbnail} width="300" height="200" /></div>
                                                </Grid>
                                                <Grid container item>
                                                    <Grid item xs={12} style={{padding: 5}}>
                                                        <TextField
                                                            id="picture-description"
                                                            label="Picture Description"
                                                            className={this.state.classes.textField}
                                                            value={this.state.propertyPictures[index].description || ''}
                                                            margin="normal"
                                                            variant="outlined"
                                                            type="text"
                                                            fullWidth
                                                            onChange={this.updatePictureDesc.bind(this, index)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item>
                                                    <Grid item container justify="center" xs={6}>
                                                        <Button variant="contained" color="primary" className={this.state.classes.button} onClick={this.handleUpdatePictureDesc.bind(this, index)}>
                                                            Save <CheckIcon className={this.state.classes.rightIcon} />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item container justify="center" xs={6}>
                                                        <Button variant="contained" color="secondary" className={this.state.classes.button} onClick={this.props.onPictureDelete.bind(this, item)}>
                                                            Delete <DeleteIcon className={this.state.classes.rightIcon} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                ))}
                            </ReactDragListView>
                        </Grid>
                    </Grid>
                </Grid>
        );
    }
}
export default CreatePropertyPhotosCmp;
